<template>
  <div style="width: 100%; height: 100%;  text-align: center" class="justify-content-center align-center align-content-center">
     <!-- <img src="/assets/images/loader2.svg" style="margin: 15px; max-width: 80px; width: 60%; vertical-align: center" ><br>-->
    <div>
      <i class="mdi mdi-spin mdi-loading" :style="'font-size: ' + size + 'px'"></i><br>
    </div>
    <span v-if="withText" style="">{{$t('loading')}}</span>
  </div>
</template>


<script>
  export default {
    props: {
      size: {
        default: "64",
        type: [String, Number],
      },
      withText: {
        default: false,
        type: Boolean
      }
    }
  }
</script>
