<template>
  <div class="container-fluid">
    <ModalForm :disabled-buttons="!!data.license" :title="$t('create_license')"
               :action-btn-name="$t('create')" ref="licenseModal"
               @save="createLicense">
      <template v-slot:content>
        <div class="container-fluid" style="margin-top: 10px; margin-bottom: 10px">
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 control-label col-form-label">{{ $t('enter_vendor') }}</label>
            <div class="col-md-9 col-sm-9">
                <input class="form-control" v-model="vendorId" >
            </div>
          </div>
          <template v-if="license" >
            <div>{{$t('count_interfaces')}}: <b>{{countOfInterfaces}}</b></div><br>
            <textarea readonly class="form-control" v-model="license" style="height:245px"></textarea>
          </template>
        </div>
      </template>
    </ModalForm>
    <ModalForm :disabled-buttons="subscriptions.disableButtons" :title="$t('add_subscription')" ref="subscriptionModal"
               @save="saveSubscription">
      <template v-slot:content>
        <div class="container-fluid" style="margin-top: 10px; margin-bottom: 10px" v-if="subscriptions.selectedSubscription !== null">
        <div class="form-group row">
          <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('name') }}</label>
          <div class="col-md-9 col-sm-9">
            <v-select class="style-chooser"
                      :clearable="false" :searchable="false"
                      v-model="subscriptions.selectedSubscription.subscription" label="name"
                      :options="subscriptions.list"></v-select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('reserved_interfaces') }}</label>
          <div class="col-md-9 col-sm-9">
            <input class="form-control" type="number"  v-model="subscriptions.selectedSubscription.reserved_interfaces">
          </div>
        </div>
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('pay_period') }}</label>
            <div class="col-md-9 col-sm-9">
              <v-select class="style-chooser"
                        :clearable="false" :searchable="false"
                        v-model="subscriptions.selectedSubscription.pay_period"
                        :options="subscriptions.pay_periods"></v-select>
            </div>
          </div>
        <hr>
        <div class="form-group row">
          <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('subscription_info') }}</label>
          <div class="col-md-9 col-sm-9" v-if="subscriptions.selectedSubscription.subscription !== null">
            <table class="table table-sm">
              <tr>
                <th>{{ $t('status') }}</th>
                <td>{{ subscriptions.selectedSubscription.status }}</td>
              </tr>
              <tr>
                <th>{{ $t('name') }}</th>
                <td>{{ subscriptions.selectedSubscription.subscription.name }}</td>
              </tr>
              <tr>
                <th>{{ $t('charging_amount') }}</th>
                <td>{{ subscriptions.selectedSubscription.subscription.charging_amount }}$</td>
              </tr>
              <tr>
                <th>{{ $t('charging_amount_by_interface') }}</th>
                <td>{{ subscriptions.selectedSubscription.subscription.charging_amount_by_interface }}$</td>
              </tr>
              <tr>
                <th>{{ $t('components') }}</th>
                <td>
                  <div style="max-height: 200px; overflow: auto"
                      v-if="subscriptions.selectedSubscription.subscription.components !== null && subscriptions.selectedSubscription.subscription.components.length > 0">
                    <li v-for="component in subscriptions.selectedSubscription.subscription.components" :key="component">
                      {{ component }}
                    </li>
                  </div>
                  <div v-else style="text-align: center">{{ $t('no_components') }}</div>
                </td>
              </tr>
            </table>
          </div>
          <div class="col-md-9 col-sm-9" v-else>
            <h4>{{ $t('choose_subscription_for_display_info') }}</h4>
          </div>
        </div>
        </div>
      </template>
    </ModalForm>
    <Preloader v-if="loading"></Preloader>
    <SomethingWrong v-if="error !== '' && !loading" :error-message="error"/>
    <div class="row" v-if="!loading && error === ''">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
        <Card :name="$t('general_info')" :show="true">
          <div class="form-group row">
            <label class="col-12 col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('UUID') }}</label>
            <div class="col-12 col-md-9 col-sm-9">
              <input type="text" v-model="data.uuid" :disabled="true"
                     class="form-control" :placeholder="$t('UUID')">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-md-3 col-sm-3 text-right control-label col-form-label">{{
                $t('ip_address')
              }}</label>
            <div class="col-7 col-md-6 col-sm-6">
              <input type="text" v-model="data.ip_address" :disabled="!changingIpAddressEnabled"
                     class="form-control" :placeholder="$t('ip_address')">
            </div>
            <div class="col-5 col-md-3 col-sm-3">
              <button v-if="!changingIpAddressEnabled" @click="changingIpAddressEnabled = !changingIpAddressEnabled"
                      class="btn btn-block btn-default">
                <i class="mdi mdi-pencil" v-if="!changingIpAddressEnabled"></i>
              </button>
              <button v-if="changingIpAddressEnabled" :disabled="savingInProcess" @click="updateIpAddress"
                      class="btn btn-block btn-default">
                <i class="mdi mdi-content-save"></i>
              </button>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12  col-md-3 col-sm-3 text-right control-label col-form-label">{{
                $t('last_active')
              }}</label>
            <div class="col-12  col-md-9 col-sm-9">
              <div v-if="data.last_active !== null">
                {{ moment(data.last_active).locale($t('lang_short')).fromNow() }}
                <br> <small> ({{ $helpers.formatTime(data.last_active) }})</small>
              </div>
              <div v-else>{{ $t('not_active') }}</div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12  col-md-3 col-sm-3 text-right control-label col-form-label">{{
                $t('client')
              }}</label>
            <div class="col-12  col-md-9 col-sm-9">
              <router-link :to="{name: 'client_edit', params: {id: data.client.id}}">
                <div>
                  <span>{{ data.client.name }} <span
                      v-if="data.client.company_name !== ''"><br><small> ({{ data.client.company_name }})</small></span></span>
                </div>
              </router-link>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12  col-md-3 col-sm-3 text-right control-label col-form-label">{{
                $t('license')
              }}</label>
            <div class="col-12  col-md-9 col-sm-9">
              <button class="btn btn-default ml-3" @click="showLicenseWindow"><i class="mdi mdi-license"></i>
                {{ $t('create_license') }}
              </button>
            </div>
          </div>
        </Card>
        <Card :name="$t('location')" :show="true" v-if="data.country && data.geo_location !== null">
          <div class="row" style="border-bottom: 1px solid #F0F0F0">
            <div class="col-12  col-md-3 col-sm-3 text-right">{{
                $t('country')
              }}:
            </div>
            <div class="col-12  col-md-9 col-sm-9">
                <b>{{data.geo_location.country}}</b>
            </div>
          </div>
          <div class="row" style="border-bottom: 1px solid #F0F0F0">
            <div class="col-12  col-md-3 col-sm-3 text-right">{{
                $t('city')
              }}:
            </div>
            <div class="col-12  col-md-9 col-sm-9">
                <b>{{data.geo_location.city}}</b>
            </div>
          </div>
          <div class="row" style="border-bottom: 1px solid #F0F0F0">
            <div class="col-12  col-md-3 col-sm-3 text-right">{{
                $t('location')
              }}:
            </div>
            <div class="col-12  col-md-9 col-sm-9">
                <b>{{data.geo_location.location.Latitude}}, {{data.geo_location.location.Longitude}}</b>
            </div>
          </div>
        </Card>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
        <Card :name="$t('subscription_detail')" :show="true">
          <div  v-if="agentIsEnabled" style="max-height: 300px; overflow: auto">
            <h4 style="text-align: center; color: darkgreen">{{$t('agent_is_enabled')}}</h4>
            <hr>
            {{$t('active_until')}}: <b> {{$helpers.formatTime(enabledUntil)}} ( {{ moment(enabledUntil).locale($t('lang_short')).fromNow() }})</b>
            <hr>
            <b>{{$t('components')}}:</b>
            <li v-for="component in componentsList" :key="component">{{component}}</li>
          </div>
          <div v-else>
            <h4 style="text-align: center; color: darkred">{{$t('no_active_subscription')}}</h4>
          </div>

        </Card>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
        <Card :name="$t('agent_usage_stat')" :show="true">
          <div  v-if="usageStat.data">
            <h4 style="text-align: center">{{$t('general_stat')}}</h4>
            <table class="style-lines" style="width: 100%">
              <tr>
                <th>{{$t('last_active')}}</th>
                <td>{{$helpers.formatTime(usageStat.data.updated_at)}}</td>
              </tr>
              <tr>
                <th>{{$t('version')}}</th>
                <td><b>{{usageStat.data.version}}</b></td>
              </tr>
              <tr>
                <th>{{$t('count_devices')}}</th>
                <td>{{usageStat.data.count_devices}}</td>
              </tr>
              <tr>
                <th>{{$t('count_interfaces')}}</th>
                <td><b>{{usageStat.data.count_interfaces}}</b></td>
              </tr>
              <tr>
                <th>{{$t('count_groups')}}</th>
                <td>{{usageStat.data.count_groups}}</td>
              </tr>
              <tr>
                <th>{{$t('count_users')}}</th>
                <td>{{usageStat.data.count_users}}</td>
              </tr>
            </table>
            <br>
            <h4 style="text-align: center">{{$t('count_devices_by_models')}}</h4>
            <div style="max-height: 250px; overflow: auto">
            <table class="table table-bordered table-sm table-striped">
              <thead>
              <tr>
                <th style="font-weight: bold">{{$t('device_model_key')}}</th>
                <th style="font-weight: bold">{{$t('count_devices')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="( count, dev) in usageStat.data.count_by_models" :key="dev">
                <td>{{dev}}</td>
                <td>{{count}}</td>
              </tr>
              </tbody>
            </table>
              </div>
          </div>
          <div v-else-if="usageStat.loading">
            <Preloader></Preloader>
          </div>
          <div v-else>
            <h4 style="text-align: center; color: darkred">{{$t('no_data_usage_statistic')}}</h4>
          </div>

        </Card>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <Card :name="$t('subscriptions')" :show="true">
          <div class="row">
            <div class="col-12">
              <button class="btn btn-default" @click="showAddingSubscriptionWindow"><i class="mdi mdi-plus"></i>
                {{ $t('add_subscription') }}
              </button>
              <v-server-table ref="tbl" :columns="table.columns" :options="table.options"
                              class="btn-add-margin-top-for-mobile table-sm">
                <template v-slot:created_at="{row}">{{ $helpers.formatTime(row.created_at) }}</template>
                <template v-slot:expired_at="{row}">{{ $helpers.formatTime(row.expired_at) }}</template>
                <template v-slot:reserved_interfaces="{row}">{{ row.reserved_interfaces }}</template>
                <template v-slot:subscription="{row}" >
                  <div style="min-width: 130px">
                  <router-link :to="{name: 'subscriptions', params: {id: row.subscription.id}}" >
                    <b>{{row.subscription.name}}</b>
                  </router-link>
                  </div>
                </template>
                <template v-slot:pay_period="{row}" >
                  {{row.pay_period}}
                </template>
                <template v-slot:status="{row}" >
                  {{row.status}}
                </template>
                <template v-slot:components="{row}">
                  <div v-if="row.subscription.components === null || row.subscription.components.length === 0">
                    {{$t('no_components')}}
                  </div>
                  <div v-else style="min-width: 190px; max-height: 150px; overflow-y: auto">
                    <div>
                    <li v-for="component in row.subscription.components" :key="component">
                      {{component}}
                    </li>
                      </div>
                  </div>
                </template>
                <template v-slot:renew_automatically="{ row }">
                  <div>
                    <input type="checkbox" :disabled="!row.status === 'ACTIVE'"  class="form-control" style="width: 28px; height: 28px" :checked="row.renew_automatically" @click="row.renew_automatically = !row.renew_automatically; updateRenew(row);">
                  </div>
                </template>
                <template v-slot:delete="{ row }">
                  <div v-if="$auth.isPermitted('clients.management')">
                    <button @click="updateSubscriptionWindow(row)" class="btn btn-info btn-sm" style="margin: 3px" :title="$t('edit')">
                      <i class="fa fa-edit"></i></button>
                  </div>
                  <div v-if="$auth.isPermitted('clients.management')">
                    <button @click="deleteRecord(row.id)" class="btn btn-danger btn-sm" style="margin: 3px" :title="$t('delete')">
                      <i class="fa fa-trash"></i></button>
                  </div>
                </template>
              </v-server-table>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>


<script>
import Preloader from "../../components/Helpers/Preloader";
import SomethingWrong from "../../components/Helpers/SomethingWrong";
import Card from "../../components/Helpers/Card";
import ModalForm from "../../components/Helpers/ModalForm";

const url = '/admin/agent'

export default {
  components: {Card, SomethingWrong, Preloader, ModalForm},
  computed: {
    componentsList() {
      let components = {}
      this.subscriptions.active_subscriptions.forEach(e => {
          e.subscription.components.forEach(component => {
            components[component] = component
          })
      })
      return components
    },
    enabledUntil() {
      let date = null
      this.subscriptions.active_subscriptions.forEach(e => {
        if(e.subscription.type === 'AGENT') {
          if (date === null) {
            date = new Date(e.expired_at);
          }
          if (date < new Date(e.expired_at)) {
            date = new Date(e.expired_at);
          }
        }
      })
      return date
    },
    agentIsEnabled() {
      let enabled = false
      this.subscriptions.active_subscriptions.some(e => {
        if( e.subscription.type === 'AGENT') {
          enabled = true
          return true
        }
        return false
      })
      return enabled
    },

  },
  data() {
    return {
      countOfInterfaces: 0,
      vendorId: null,
      license: null,
      changingIpAddressEnabled: false,
      agentId: 0,
      data: {},
      usageStat: {
        data: null,
        loading: true,
      },
      subscriptions: {
        list: [],
        pay_periods: ['TRIAL', 'MONTH', 'YEAR'],
        active_subscriptions: [],
        selectedSubscription: null,
        disableButtons: false,
      },
      savingInProcess: false,
      loading: true,
      error: '',
      table: {
        columns: ['id', 'created_at', 'expired_at', 'charged_amount', 'status', 'reserved_interfaces', 'subscription', 'pay_period', 'components', 'renew_automatically', 'delete'],
        options: {
          headings: {
            id: 'Id',
            created_at: this.$t('created_at'),
            expired_at: this.$t('expired_at'),
            subscription: this.$t('subscription'),
            reserved_interfaces: this.$t('reserved_interfaces'),
            pay_period: this.$t('pay_period'),
            charged_amount: this.$t('charged_amount'),
            status: this.$t('status'),
            renew_automatically: this.$t('renew_automatically'),
            delete: this.$t('delete'),
          },
          perPage: 30,
          requestFunction: (data) => {
            console.log(this.agentId)
            return this.$api.get('/admin/agent-subscription/by-agent/' + this.agentId, {
              limit: data.limit,
              page: data.page,
            }).catch(function (e) {
              console.log(e)
            }).then(d => {
              return {data: d.data, count: d.meta.count_records}
            });
          },
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table',
          sortable: [],
          filterable: false,

          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
    }
  },
  async mounted() {
    this.loading = true
    this.agentId = this.$route.params.id
    await this.load()
  },
  watch: {},
  methods: {
    agentUsageStat() {
      this.usageStat.loading = true
      this.$api.get('/admin/agent/usage-stat/' + this.agentId, null, true).then(data => {
        this.usageStat.data = data.data
      }).catch(() => {
        this.usageStat.data = null
      }).finally(() => {
        this.usageStat.loading = false
      })
    },
    deleteRecord(id) {
      if (confirm(this.$t("are_you_sure_for_delete_record"))) {
        this.loading = true
        this.$api.delete('/admin/agent-subscription/' + id).then(() => {
          this.$noty.success(this.$t('record_success_deleted'))
          this.loading = false
          this.load()
        })
      }
    },
    setAgent(resp) {
      this.data = resp
      this.vendorId = this.data.vendor_id
    },
    async updateIpAddress() {
      this.savingInProcess = true
      let data = {
        ip_address: this.data.ip_address,
      }
      await this.$api.put('/admin/agent/' + this.agentId, data).then(data => {
        this.setAgent(data.data)
        this.savingInProcess = false
        this.$noty.success(this.$t('agent_successful_updated'))
      }).catch(() => {
        this.$api.load()
      })
      this.changingIpAddressEnabled = false
      this.savingInProcess = false
    },
    async load() {
      this.loading = true
      await this.$api.get('/admin/agent/' + this.agentId).then(data => {
        this.setAgent(data.data)
        this.$setRouteMeta(this.$t('agent_edit', {name: data.data.uuid}))
      }).catch(() => {
        this.error = 'ERROR'
      })
      await this.$api.get('/admin/agent-subscription/by-agent/' + this.agentId, {
        limit: 10000,
      }).then(data => {
        this.subscriptions.active_subscriptions = data.data
      }).catch(() => {
        this.error = 'ERROR'
      })
      await this.$api.get('/admin/subscription').then(data => {
        this.subscriptions.list = data.data
      }).catch(() => {
        this.error = 'ERROR'
      })
      this.loading = false
      this.agentUsageStat()
    },
    updateSubscriptionWindow(subscription) {
      this.subscriptions.selectedSubscription = subscription
      this.$refs.subscriptionModal.show()
    },
    showAddingSubscriptionWindow() {
      this.subscriptions.selectedSubscription = {
        id: null,
        agent: this.data,
        subscription: null,
        count_interfaces: 0,
        pay_period: 'TRIAL',
      }
      this.$refs.subscriptionModal.show()
    },
    showLicenseWindow() {
      this.$refs.licenseModal.show()
    },
    async createLicense() {
      await this.$api.get(`/admin/agent/box-license/${this.agentId}`, { vendor_id: this.vendorId}).then(({ data }) => {
        console.log(data)
        this.license = data.license
        this.countOfInterfaces = data.object.reserved_interfaces

        // this.$noty.success(this.$t('subscription_success_added'))
        // this.load()
        // this.$refs.subscriptionModal.hide()
      }).finally(() => {
      })
    },
    saveSubscription() {
      this.subscriptions.disableButtons = true
      let subscr = this.subscriptions.selectedSubscription
      subscr.reserved_interfaces = Number(subscr.reserved_interfaces)
      if(this.subscriptions.selectedSubscription.id === null) {
        this.$api.post('/admin/agent-subscription', subscr).then(r => {
          this.$noty.success(this.$t('subscription_success_added'))
          this.load()
          this.$refs.subscriptionModal.hide()
        }).finally(() => {
          this.subscriptions.disableButtons = false
        })
      } else {
        this.$api.put('/admin/agent-subscription/' + subscr.id, {
          subscription: subscr.subscription,
          reserved_interfaces: subscr.reserved_interfaces,
          pay_period: subscr.pay_period,
        }).then(() => {
          this.$refs.subscriptionModal.hide()
          this.$noty.success(this.$t('renew_success_updated_for_subscription', {id: subscr.id}))
        }).finally(() => {
          this.subscriptions.disableButtons = false
        })
      }
    },
    updateRenew(row) {
       this.$api.put('/admin/agent-subscription/' + row.id, {
         renew_automatically: row.renew_automatically
       }).then(() => {
         this.$noty.success(this.$t('renew_success_updated_for_subscription', {id: row.id}))
       })
    }
  }
}
</script>
<style scoped>
.style-lines tr th {
  padding-right: 10px;
  border-bottom: 1px solid #F0F0F0;
}
.style-lines tr td {
  padding-right: 10px;
  border-bottom: 1px solid #F0F0F0;
}
</style>