import Dashboard from '../pages/Dashboard/Dashboard'
import PageNotFound from "../pages/PageNotFound";
import Login from "../pages/Login";
import Main from "../components/Main";
import VueRouter from "vue-router";
import Account from "@/pages/Managment/Account";
import Users from "@/pages/Users/Users";
import ListUser from "@/pages/Users/List";
import EditUser from "@/pages/Users/Edit";
import Components from "@/pages/Managment/Components";
import Subscriptions from "@/pages/Managment/Subscriptions";
import Releases from "@/pages/Managment/Releases";
import Client from "@/pages/Clients/Client";
import ListClients from "@/pages/Clients/List";
import EditClient from "@/pages/Clients/Edit";
import List from "@/pages/Agents/List";
import Edit from "@/pages/Agents/Edit";

const routes = [
    {
        path: "/login",
        name: "login",
        meta: {
            title: '',
            allowAnonymous: true,
        },
        component: Login,
    },
    {
        path: "/",
        component: Main,
        meta: {
            title: ""
        },
        children: [
            {
               path: "/account/settings",
               meta: {
                   title: '',
               },
               name: "account_settings",
               component: Account,
            },
            {
                path: "",
                meta: {
                    title: '',
                },
                name: "dashboard",
                component: Dashboard,
            },
            {
                path: "subscriptions/:id?",
                meta: {
                    title: '',
                    breadcrumbs: ['dashboard'],
                },
                name: "subscriptions",
                component: Subscriptions,
            },
            {
                path: "releases",
                meta: {
                    title: '',
                    breadcrumbs: ['dashboard'],
                },
                name: "releases",
                component: Releases,
            },
            {
                path: "components",
                meta: {
                    title: '',
                    breadcrumbs: ['dashboard'],
                },
                name: "components",
                component: Components,
            },

            {
                path: "clients",
                meta: {
                    title: '',
                    breadcrumbs: ['dashboard']
                },
                component: Client,
                children: [
                    {
                        path: "",
                        meta: {
                            title: '',
                            breadcrumbs: ['dashboard'],
                        },
                        name: "clients",
                        component: ListClients,
                    },
                    {
                        path: ":id",
                        meta: {
                            title: '',
                            breadcrumbs: ['dashboard', 'clients'],
                        },
                        name: "client_edit",
                        component: EditClient,
                    }
                ]
            },
            {
                path: "agents",
                meta: {
                    title: '',
                    breadcrumbs: ['dashboard']
                },
                component: Client,
                children: [
                    {
                        path: "",
                        meta: {
                            title: '',
                            breadcrumbs: ['dashboard'],
                        },
                        name: "agents",
                        component: List,
                    },
                    {
                        path: ":id",
                        meta: {
                            title: '',
                            breadcrumbs: ['dashboard', 'agents'],
                        },
                        name: "agent_edit",
                        component: Edit,
                    }
                ]
            },
            {
                path: "users",
                meta: {
                    title: '',
                    breadcrumbs: ['dashboard']
                },
                component: Users,
                children: [
                    {
                        path: "",
                        meta: {
                            title: '',
                            breadcrumbs: ['dashboard'],
                        },
                        name: "management_users_list",
                        component: ListUser,
                    },
                    {
                        path: ":id",
                        meta: {
                            title: '',
                            breadcrumbs: ['dashboard', 'management_users_list'],
                        },
                        name: "management_user_edit",
                        component: EditUser,
                    }
                ]
            },
        ]
    },
    {
        path: '/:catchAll(.*)',
        meta: {
            title: 'Page not found',
            false: true,
        },
        component: PageNotFound,
    },
];

const router = new VueRouter({
    routes: routes,
    mode: 'history',
    linkActiveClass: 'active',
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

export default router;
