<template>
  <transition name="fade">
    <div @click="hideClick" v-if="show"
         :style="`position: fixed;
         overflow: hidden;
         bottom: 0px;
         right: 0;
         height: 70px;
         margin: 0;
         width: 100%;
         margin-left: -10px;
         padding-top: 10px;
         padding-bottom: 10px;
         background-color: ${color};
         z-index: 10;
         text-align: center;
         color: black;
         `"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12" v-html="html"></div>
        </div>
      </div>
    </div>
  </transition>
</template>


<script>
  export default {
      data() {
        return {
          show: false,
          html: '',
        }
      },
      props: {
         timeout: {
           type: Number,
           default: 10000,
         },
         hideOnClick: {
           type: Boolean,
           default: true,
         },
         color: {
           type: String,
           default: 'rgba(253,112,36,0.8)',
         },
      },
      methods: {
        hideClick() {
          if(this.hideOnClick) {
            this.show = false
          }
        },
        displayNoty(html = '') {
          this.html = html
          this.show = true
          if(this.timeout !== 0) {
            setTimeout(() => {
              this.show = false
            }, this.timeout)
          }
        }
      }
  }
</script>