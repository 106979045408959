<template>
  <footer class="footer text-right" style="padding-bottom: 50px">
    All Rights Reserved by <a href="https://wildcore.tools" target="_blank">wildcore.tools</a><br>
  </footer>
</template>


<script>
 export default {

 }
</script>
