import defaults from './config/global'
import router from './common/router'
import App from "./App";
import ServerApi from "./common/http";
import Auth from "./common/store/auth";
import VueNoty from "./common/noty";
import Vue from 'vue'
import VueRouter from 'vue-router'
import {ServerTable, ClientTable} from 'vue-tables-2';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import '@mdi/font/css/materialdesignicons.min.css';
import VueI18n from 'vue-i18n'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faSort, faSortUp, faSortDown  } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import MySortControl from "@/components/Helpers/Table/MySortControl";
import VModal from 'vue-js-modal'
import helpers from "@/common/helpers";
import moment from 'moment'
import {createPopper} from '@popperjs/core';

library.add(faUserSecret)
library.add(faSort);
library.add(faSortUp);
library.add(faSortDown);
library.add(faSortDown);
import JsonTree from 'vue-json-tree'
import MyFilter from "@/components/Helpers/Table/MyFilter";
import MyTable from "@/components/Helpers/Table/MyTable";
import LocalConfig from "@/common/store/localConfig";
let attr = document.getElementById("app");
let baseUrl = attr.getAttribute('api-base-url');
if(baseUrl !== null) {
    console.log("Set from attr:  api-base-url="+baseUrl)
    defaults.apiBaseUrl = baseUrl
}

const api = new ServerApi(defaults.apiBaseUrl)
const auth = new Auth(api)
const locCfg = new LocalConfig()
const noty = new VueNoty()

if(auth.isLogged()) {
    api.setToken(auth.getAuthKey())
}

api.setNoty(noty)

api.set401callback(()=>{
    noty.info("You session is closed, try re-login", {
        killer : true
    })
    auth.logout()
    window.location.href = "/login"
})

Array.prototype.remove = function() {
    var what, a = arguments, L = a.length, ax;
    while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
            this.splice(ax, 1);
        }
    }
    return this;
};

router.beforeEach((to, from, next) => {

    if(to.matched.some(record => typeof record.meta.allowAnonymous === 'undefined' || !record.meta.allowAnonymous )) {
        if (auth.isLogged()) {
            next()
            return
        }
        next('/login?redirect=' + to.path)
    } else {
        next()
    }
})

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
});

Vue.use(VueRouter)
Vue.use(ClientTable, {}, false, 'bootstrap4',  {
    sortControl: MySortControl,
    genericFilter: MyFilter,
    dataTable: MyTable,
})

Vue.use(ServerTable, {limit: 50}, false, 'bootstrap4',  {
    sortControl: MySortControl,
    genericFilter: MyFilter,
    dataTable: MyTable,
})

Vue.use(VueI18n)
Vue.use(VModal, {dialog: true})
const i18n = new VueI18n({
    locale: defaults.locale.default_locale, // установка локализации по умолчанию
    messages: defaults.locale.locales,
})

api.setI18N(i18n)

Vue.component('json-tree', JsonTree)
Vue.component('v-select', vSelect)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.prototype.$createPopper = createPopper
Vue.prototype.$config = defaults
Vue.prototype.$noty = noty
Vue.prototype.$api = api
Vue.prototype.$auth = auth
Vue.prototype.$localCfg = locCfg
Vue.prototype.$helpers = helpers
Vue.prototype.moment = moment

var filter = function(text, length, clamp){
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);

const app = new Vue({
    router,
    i18n,
    render: h => h(App),
});

const meta = Vue.observable({ routeMeta: {} })

Object.defineProperty(Vue.prototype, '$routeMeta', {
    get () {
        return meta.routeMeta
    },
    set (value) {
        meta.routeMeta = value
    }
})


Vue.prototype.$setRouteMeta = function (title, params = {}) {
    Vue.prototype.$routeMeta = {
        title: title,
        params: params,
    }
}
document.getElementById('preloader-web-portal').style = 'display: none;'
app.$mount('#app')
