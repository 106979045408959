<template>
  <ul class="navbar-nav float-left" style="flex-grow: 1">
    <li class="nav-item d-none d-md-block"><a class="nav-link sidebartoggler waves-effect waves-light"
                                              href="javascript:void(0)" data-sidebartype="mini-sidebar"><i
        class="mdi mdi-menu font-24"></i></a></li>
    <!-- ============================================================== -->
    <!-- create new -->
    <!-- ============================================================== -->
    <li class="nav-item dropdown">
      <!--
      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="d-none d-md-block">Create New <i class="fa fa-angle-down"></i></span>
        <span class="d-block d-md-none"><i class="fa fa-plus"></i></span>
      </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        <a class="dropdown-item" href="#">Action</a>
        <a class="dropdown-item" href="#">Another action</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="#">Something else here</a>
      </div>
      -->
    </li>
    <!-- ============================================================== -->
    <!-- Search -->
    <!-- ============================================================== -->
    <li class="nav-item dropdown float-lg-left" style="flex: auto">
      <div class="container-fluid" style="margin-left: 15px;margin-top: 15px" v-click-outside="hideSearch">
        <transition name="slide-fade">
          <div class="row" v-show="showSearchForm">
            <div class="col-sm-12 col-12" style="min-width: 330px; max-width: 550px; width: 100%">
              <!--<input type="text" class="form-control" placeholder="Search &amp; enter">-->
              <v-select class="global-search" @search="fetchOptions" :filterable="false" :options="options"
                        label="title"
                        height="300px"
                        :autoscroll="false"
                        ref="searchLine"
                        v-model="selected"
                        @input="processSeletected"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching && search.length >= 3">
                    {{ $t('no_results') }}<em>{{ search }}</em
                  >.
                  </template>
                  <em v-else style="opacity: 0.5">{{ $t('type_more_symbols_for_search') }}</em>
                </template>
                <template v-slot:option="option">
                  <div style="margin-top: 5px; margin-bottom: 5px">
                    <div v-if="option.type === 'ont_ident'">
                      <div class="row">
                        <div class="col-1 d-none d-md-block"><i class="mdi mdi-24px mdi-router"></i></div>
                        <div class="col-12 col-md-11 col-sm-11 col-lg-11">
                          {{ $t('ont_ident') }}: <b>{{ option.data.ident }}</b><br>
                          <i>{{ $t('interface') }}: {{ option.data.interface.name }}, {{ $t('on_device') }}
                            {{ option.data.interface.device.name }}</i>
                        </div>
                      </div>
                    </div>
                    <div v-if="option.type === 'fdb_history'">
                      <div class="row">
                        <div class="col-1 d-none d-md-block"><i class="mdi mdi-24px mdi-ethernet"></i></div>
                        <div class="col-12 col-md-11 col-sm-11 col-lg-11" style="word-wrap: break-word !important;">
                          {{ $t('mac_in_fdb') }}: <b>{{ option.data.mac_address }} ({{ option.data.vlan_id }})</b><br>
                          <i>{{ $t('interface') }}: {{ option.data.interface.name }}, {{ $t('on_device') }}
                            {{ option.data.interface.device.name }}</i>
                        </div>
                      </div>
                    </div>
                    <div v-if="option.type === 'device'">
                      <div class="row">
                        <div class="col-1 d-none d-md-block"><i class="mdi mdi-24px mdi-server-network"></i></div>
                        <div class="col-12 col-md-11 col-sm-11 col-lg-11" style="word-wrap: break-word !important;">
                          {{ $t('device') }}: <b>{{ option.data.name }} ({{ option.data.ip }})</b><br>
                          <i>{{ $t('model') }}: {{ option.data.model.name }}</i>
                        </div>
                      </div>
                    </div>
                    <div v-if="option.type === 'interface'">
                      <div class="row">
                        <div class="col-1 d-none d-md-block"><i class="mdi mdi-24px mdi-ethernet-cable"></i></div>
                        <div class="col-12 col-md-11 col-sm-11 col-lg-11" style="word-wrap: break-word !important;">
                          {{ $t('interface') }}: <b>{{ option.data.name }} ({{ option.data.type }})</b><br>
                          <i>{{ $t('on_device') }} {{ option.data.device.name }} ({{ option.data.device.ip }})</i>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </v-select>
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div class="row" v-show="showSearchBtn">
           <!-- <div class="col-sm-2 col-2">
              <a class="nav-link" @click="showSearch"><i class="ti-search"></i></a>
            </div>-->
          </div>
        </transition>
      </div>
    </li>
  </ul>
</template>


<script>
export default {
  data() {
    return {
      showSearchForm: false,
      showSearchBtn: true,
      options: [],
      selected: null,
    }
  },
  watch: {},
  methods: {
    processSeletected(selected = null) {
      if (selected === null) {
        selected = this.selected
      }
      switch (selected.type) {
        case 'interface':
          if (selected.data.type === 'ONU') {
            this.$router.push({
              name: 'device_swcore_interface_info',
              params: {
                id: selected.data.device.id,
                interface: selected.data.bind_key
              }
            })
          } else {
            this.$router.push({
              name: 'device_swcore_info',
              params: {
                id: selected.data.device.id
              }
            })
          }
          break;
        case 'fdb_history':
          if (selected.data.interface.type === 'ONU') {
            this.$router.push({
              name: 'device_swcore_interface_info',
              params: {
                id: selected.data.interface.device.id,
                interface: selected.data.interface.bind_key
              }
            })
          } else {
            this.$router.push({
              name: 'device_swcore_info',
              params: {
                id: selected.data.interface.device.id
              }
            })
          }
          break;
        case 'ont_ident':
          this.$router.push({
            name: 'device_swcore_interface_info',
            params: {
              id: selected.data.interface.device.id,
              interface: selected.data.interface.bind_key
            }
          });
          break;
        case 'device':
          this.$router.push({
            name: 'device_swcore_info',
            params: {
              id: selected.data.id
            }
          })
          break;
      }
      this.hideSearch()
      this.options = []
    },
    showSearch() {
      this.showSearchBtn = false
      setTimeout(() => {
        this.showSearchForm = true
        setTimeout(() => {
          this.$nextTick(() => {
            const input = this.$refs.searchLine.$el.querySelector('input')
            input.focus()
          })
        }, 100)
      }, 100)
    },
    hideSearch() {
      this.showSearchForm = false
      this.options = []
      setTimeout(() => {
        this.showSearchBtn = true
      }, 400)
    },
    fetchOptions(search, loading) {
      if (search.length < 3) {
        return null
      }
      loading(true)
      this.$api.post('/portal/search', {query: search}).then(r => {
        let data = []
        r.data.forEach(d => {
          let title = ''
          switch (d.type) {
            case 'fdb_history':
              title = '';
              break;
            case 'device':
              title = '';
              break;
            case 'ont_ident':
              title = '';
              break;
            case 'interfaces':
              title = '';
              break;
          }
          d.title = title
          data.push(d)
        })
        this.options = data
        loading(false)
      })
    },
  },
}
</script>

<style scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .5s ease;
}

.slide-fade-leave-active {
  transition: all .3s ease;
}

.fade-leave-active {
  transition: all 0s ease;
}

.fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}

</style>
<style>


.global-search .vs__search::placeholder,
.global-search .vs__dropdown-toggle,
.global-search .vs__dropdown-menu {
  background: #EFEFEF;
  border: none;
  border-radius: 1px;
  color: #1e243b;
}

.global-search .vs__dropdown-toggle {
  height: 35px;
}

.global-search .vs__clear,
.global-search .vs__open-indicator {
  fill: #1c2031;

}
</style>
