<template>
  <div class="card ">

    <div class="row" style="margin: 10px; margin-bottom: 5px">
      <div class="col-sm-10"><h6 style="text-align: center">{{$t('releases')}}</h6></div>
      <div class="col-sm-2"><button @click="$router.push({name: 'releases'})" class="btn btn-outline-info btn-block"><i class="fa fa-arrow-right"></i></button></div>
    </div>
    <v-client-table ref="table" v-model="data" :columns="table.columns" :options="table.options"
                    style="margin-top: 5px; ">
      <template v-slot:created_at="{row}">{{ $helpers.formatTime(row.created_at) }}</template>
      <template v-slot:updated_at="{row}">{{ $helpers.formatTime(row.updated_at) }}</template>
      <template v-slot:version="{row}"><b>{{row.version}}</b></template>
      <template v-slot:change_log="{row}"><pre style="max-height: 150px; margin: -10px; padding: 0;" >{{row.change_log}}</pre></template>
      <template v-slot:actions="{row}">
        <div>
          <button @click="editRecord(row)" class="btn btn-default" style="margin: 3px" :title="$t('edit')"><i
              class="fa fa-edit"></i></button>
          <button @click="deleteRecord(row.id)" class="btn btn-danger" style="margin: 3px" :title="$t('delete')">
            <i class="fa fa-trash"></i></button>
        </div>
      </template>
    </v-client-table>
  </div>
</template>


<script>

const url = '/admin/release'

export default {
  props: {
    list:  {
      type: Array,
    },
  },
  mounted() {
    this.load()
  },

  data() {
    return {
      data: [],
      table: {
        columns: ['created_at', 'version', 'change_log'],
        options: {
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table',
          headings: {
            created_at: this.$t('created_at'),
            version: this.$t('version'),
            change_log: this.$t('change_log'),
          },
          sortable: ['id', 'version'],
          filterable: false,
          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
    }
  },
  methods: {
    tableData(dt) {
      var data = []
      dt.forEach(record => {
        data.push({
          disableButtons: false,
          title: this.$t('edit_record_with_key', {id: record.key}),
          created_at: record.created_at,
          updated_at: record.updated_at,
          id: record.id,
          version: record.version,
          change_log:  record.change_log,
        })
      })
      return data
    },
    async load() {
      this.loading = true
      await this.$api.get(url).then(data => {
        this.data = this.tableData(data.data)
      }).catch(() => {
        this.error = 'ERROR'
      })
      this.loading = false
    },
  }

}
</script>