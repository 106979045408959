var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[(_vm.error !== '')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('SomethingWrong')],1)]):_vm._e(),(_vm.error === '')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{},[_c('button',{staticClass:"btn btn-default",staticStyle:{"float":"right","margin-bottom":"10px"},on:{"click":_vm.addRecord}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('create_new_agent'))+" ")]),_c('v-server-table',{ref:"tbl",staticClass:"btn-add-margin-top-for-mobile",attrs:{"columns":_vm.table.columns,"options":_vm.table.options},scopedSlots:_vm._u([{key:"created_at",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.$helpers.formatTime(row.created_at)))]}},{key:"last_active",fn:function(ref){
var row = ref.row;
return [(row.last_active !== null)?_c('div',[_vm._v(" "+_vm._s(_vm.moment(row.last_active).locale(_vm.$t('lang_short')).fromNow())+" "),_c('br'),_vm._v(" "),_c('small',[_vm._v(" ("+_vm._s(_vm.$helpers.formatTime(row.last_active))+")")])]):_c('div',[_vm._v(_vm._s(_vm.$t('not_active')))])]}},{key:"ip_address",fn:function(ref){
var row = ref.row;
return [_c('div',[(row.ip_address !== null && row.ip_address !== '')?_c('span',[_vm._v(_vm._s(row.ip_address))]):_c('span',[_vm._v(_vm._s(_vm.$t('not_registered')))])])]}},{key:"client",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"to":{name: 'client_edit', params: {id: row.client.id}}}},[_c('div',[_vm._v(" "+_vm._s(row.client.name)+" "),(row.client.company_name !== '')?_c('span',[_c('br'),_c('small',[_vm._v("("+_vm._s(row.client.company_name)+")")])]):_vm._e()])])]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [(_vm.$auth.isPermitted('clients.management'))?_c('div',[_c('router-link',{staticClass:"btn btn-default",staticStyle:{"margin":"3px"},attrs:{"to":{name: 'agent_edit', params: {id:row.id}},"title":_vm.$t('edit')}},[_c('i',{staticClass:"fa fa-edit"})]),_c('button',{staticClass:"btn btn-danger",staticStyle:{"margin":"3px"},attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.deleteRecord(row.id)}}},[_c('i',{staticClass:"fa fa-trash"})])],1):_vm._e()]}}],null,false,1432050077)})],1)])]):_vm._e(),_c('ModalForm',{ref:"editRecord",attrs:{"disabled-buttons":_vm.modalDisableBtns,"title":_vm.$t('create_new_agent')},on:{"save":_vm.saveRecord},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{staticClass:"container-fluid"},[_c('div',{staticClass:"form-group row",staticStyle:{"margin-top":"20px","margin-bottom":"20px"}},[_c('label',{staticClass:"col-md-3 col-sm-3 text-right control-label col-form-label"},[_vm._v(_vm._s(_vm.$t('client')))]),_c('div',{staticClass:"col-md-9 col-sm-9"},[_c('v-select',{staticClass:"client-search",attrs:{"filterable":false,"options":_vm.searchClients.options,"height":"300px","autoscroll":false},on:{"search":_vm.searchClientsFetchOptions},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" "+_vm._s(_vm.$t('no_results'))),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_vm._e()]}},{key:"option",fn:function(option){return [_c('div',{staticStyle:{"margin-top":"5px","margin-bottom":"5px"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12 col-sm-12 col-lg-12"},[(option.name !== '')?_c('span',[_vm._v(_vm._s(option.name)+" "),(option.company_name !== '')?_c('span',[_c('small',[_vm._v("("+_vm._s(option.company_name)+")")])]):_vm._e()]):_c('span',[_vm._v(_vm._s(_vm.$t('no_name')))])])])])]}}]),model:{value:(_vm.searchClients.selected),callback:function ($$v) {_vm.$set(_vm.searchClients, "selected", $$v)},expression:"searchClients.selected"}})],1)]),_c('div',{staticClass:"form-group row",staticStyle:{"margin-top":"20px","margin-bottom":"20px"}},[_c('label',{staticClass:"col-md-3 col-sm-3 text-right control-label col-form-label"},[_vm._v(_vm._s(_vm.$t('ip_address')))]),_c('div',{staticClass:"col-md-9 col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ipAddress),expression:"ipAddress"}],staticClass:"form-control",attrs:{"name":"ip_address"},domProps:{"value":(_vm.ipAddress)},on:{"input":function($event){if($event.target.composing){ return; }_vm.ipAddress=$event.target.value}}})])])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }