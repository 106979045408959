import menus from "./menus";
import en from './locales/en'

export default {
    apiBaseUrl: "/api/v1",
    locale: {
        default_locale: 'en',
        locales: {
            en: en,
        },
    },

    name: 'WildCore Admin',
    homepage: '/',
    menu: menus,

}
