<template>
  <div class="container-fluid">
    <div class="row" v-if="error !== ''">
      <div class="col-12">
        <SomethingWrong/>
      </div>
    </div>
    <div class="row" v-if="error === ''">
      <div class="col-12">
        <div v-cloak>
          <button class="btn btn-default" @click="addRecord" style="float: right; margin-bottom: 10px"><i
              class="fa fa-plus"></i> {{ $t('create_new_agent') }}
          </button>
          <v-server-table ref="tbl" :columns="table.columns" :options="table.options"
                          class="btn-add-margin-top-for-mobile">
            <template v-slot:created_at="{row}">{{ $helpers.formatTime(row.created_at) }}</template>
            <template v-slot:last_active="{row}">
              <div  v-if="row.last_active !== null">
                {{moment(row.last_active).locale($t('lang_short')).fromNow()}}
                <br> <small> ({{$helpers.formatTime(row.last_active)}})</small>
              </div>
              <div v-else>{{$t('not_active')}}</div>
            </template>
            <template v-slot:ip_address="{row}">
              <div>
                <span v-if="row.ip_address !== null && row.ip_address !== ''">{{row.ip_address}}</span>
                <span v-else>{{$t('not_registered')}}</span>
              </div>
            </template>
            <template v-slot:client="{row}">
              <router-link :to="{name: 'client_edit', params: {id: row.client.id}}">
                <div>
                  {{row.client.name}}
                  <span v-if="row.client.company_name !== ''"><br><small>({{row.client.company_name}})</small></span>
                </div>
              </router-link>
            </template>
            <template v-slot:actions="{ row }">
              <div v-if="$auth.isPermitted('clients.management')">
                <router-link :to="{name: 'agent_edit', params: {id:row.id}}" class="btn btn-default" style="margin: 3px"
                             :title="$t('edit')"><i class="fa fa-edit"></i></router-link>
                <button @click="deleteRecord(row.id)" class="btn btn-danger" style="margin: 3px" :title="$t('delete')">
                  <i class="fa fa-trash"></i></button>
              </div>
            </template>
          </v-server-table>
        </div>
      </div>
    </div>
    <ModalForm :disabled-buttons="modalDisableBtns" :title="$t('create_new_agent')" ref="editRecord" @save="saveRecord">
      <template v-slot:content>
        <form class="container-fluid">
          <div class="form-group row" style="margin-top: 20px; margin-bottom: 20px">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('client') }}</label>
            <div class="col-md-9 col-sm-9">
              <v-select class="client-search" @search="searchClientsFetchOptions" :filterable="false"
                        :options="searchClients.options"
                        height="300px"
                        :autoscroll="false"
                        v-model="searchClients.selected"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{ $t('no_results') }}<em>{{ search }}</em
                  >.
                  </template>
                </template>
                <template v-slot:option="option">
                  <div style="margin-top: 5px; margin-bottom: 5px">
                    <div class="row">
                      <div class="col-12 col-md-12 col-sm-12 col-lg-12">
                        <span v-if="option.name !== ''">{{option.name}} <span v-if="option.company_name !== ''"><small>({{option.company_name}})</small></span></span>
                        <span v-else>{{$t('no_name')}}</span>
                      </div>
                    </div>
                  </div>
                </template>
              </v-select>
            </div>
          </div>
          <div class="form-group row" style="margin-top: 20px; margin-bottom: 20px">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('ip_address') }}</label>
            <div class="col-md-9 col-sm-9">
                <input name="ip_address" v-model="ipAddress" class="form-control">
            </div>
          </div>
        </form>
      </template>
    </ModalForm>
  </div>
</template>


<script>
import SomethingWrong from "../../components/Helpers/SomethingWrong";
import ModalForm from "../../components/Helpers/ModalForm";

const url = '/admin/agent'

export default {
  components: {ModalForm, SomethingWrong,},
  data() {
    return {
      ipAddress: '',
      searchClients: {
        options: [],
        selected: null,
      },
      modalDisableBtns: false,

      table: {
        columns: ['id', 'created_at', 'uuid','ip_address', 'last_active', 'client',  'actions'],
        options: {
          headings: {
            id: 'Id',
            created_at: this.$t('created_at'),
            uuid: this.$t('uuid'),
            ip: this.$t('ip'),
            client: this.$t('client'),
            last_active: this.$t('last_active'),
            actions: '',
          },
          perPage: 50,
          perPageValues: [50, 100,  500, 1000],
          requestFunction(data) {
            return this.$api.get("/admin/agent", {
              query: data.query,
              asc: data.ascending === 1 ? "asc" : "desc",
              limit: data.limit,
              page: data.page,
              order_by: data.orderBy,
            }).catch(function (e) {
              console.log(e)
            }).then(d => {
              return {data: d.data, count: d.meta.count_records}
            });
          },
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table',
          sortable: ['id', 'created_at', 'uuid', 'ip_address', 'last_active'],
          filterable: ['username', 'email'],

          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
      error: '',
    }
  },
  mounted() {
    this.$setRouteMeta(this.$t('agents'))
    this.load()
  },
  methods: {
    deleteRecord(id) {
      if (confirm(this.$t("are_you_sure_for_delete_record"))) {
        this.loading = true
        this.$api.delete(url + '/' + id).then(() => {
          this.$noty.success(this.$t('record_success_deleted'))
          this.loading = false
          this.load()
        })
      }
    },
    saveRecord() {
      this.modalDisableBtns = true
      if (this.searchClients.selected === null) {
        this.$noty.error(this.$t('client_is_required'))
        this.modalDisableBtns = false
        return
      }
      let data = {
        client: {
          id: this.searchClients.selected.id,
        }
      }
      if (this.ipAddress !== "") {
        data.ip_address = this.ipAddress
      }
      this.$api.post(url, data).then((d) => {
        this.$noty.success(this.$t('record_success_created'))
        this.$refs.editRecord.hide()
        this.modalDisableBtns = false
        this.$router.push({name: 'agent_edit', params: {id: d.data.id}})
      }).finally(() => {
        this.modalDisableBtns = false
      })
    },
    addRecord() {
      this.$refs.editRecord.show()
    },

    async load() {
      this.$refs.tbl.refresh()
      this.$api.get('/admin/client', {order_by: 'name'}).then(r => {
        let data = []
        r.data.forEach(d => {
          d.label = `${d.name} `
          if(d.company_name !== "") {
            d.label += `(${d.company_name})`
          }
          data.push(d)
        })
        this.searchClients.options = data
      })
    },

    searchClientsFetchOptions(search, loading) {
      loading(true)
      this.$api.get('/admin/client', {query: search, order_by: 'name'}).then(r => {
        let data = []
        r.data.forEach(d => {
          d.label = `${d.name} `
          if(d.company_name !== "") {
            d.label += `(${d.company_name})`
          }
          data.push(d)
        })
        this.searchClients.options = data
        loading(false)
      })
    },
  }
}
</script>

<style >
.client-search .vs__search::placeholder,
.client-search .vs__dropdown-toggle,
.client-search .vs__dropdown-menu {
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  border-radius: 1px;
  color: #1e243b;
}

.client-search .vs__dropdown-toggle {
  height: 35px;
}

.client-search .vs__clear,
.client-search .vs__open-indicator {
  fill: #1c2031;

}

</style>