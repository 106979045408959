<template>
  <div class="container-fluid">
    <ModalForm :disabled-buttons="payments.disableBtn" :title="$t('add_payment')" ref="paymentModal"
               @save="savePayment" >
      <template v-slot:content>
        <div class="container-fluid" style="margin-top: 10px; margin-bottom: 10px" v-if="payments.choosed !== null">
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('payment_type') }}</label>
            <div class="col-md-9 col-sm-9">
              <v-select class="style-chooser"
                        :clearable="false" :searchable="false"
                        v-model="payments.choosed.payment_type" label="name"
                        :options="payments.payment_type"></v-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('status') }}</label>
            <div class="col-md-9 col-sm-9">
              <v-select class="style-chooser"
                        :clearable="false" :searchable="false"
                        v-model="payments.choosed.status" label="name"
                        :options="payments.statuses"></v-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('amount') }}</label>
            <div class="col-md-9 col-sm-9">
              <input class="form-control" type="number" step="0.01" v-model="payments.choosed.amount">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('order_description') }}</label>
            <div class="col-md-9 col-sm-9">
              <textarea v-model="payments.choosed.order_description" class="form-control"></textarea>
            </div>
          </div>
        </div>
      </template>
    </ModalForm>
    <ModalForm :disabled-buttons="emailview.disable_btn" :title="emailview.title" ref="viewEmailInfo" :modal-width="800">
      <template v-slot:content>
         <div v-if="emailview.record !== null">
           <div  style="margin: 15px">
           <div><b>ID</b>: {{emailview.record.id}}</div>
           <div><b>Send At</b>: {{emailview.record.send_at}}</div>
           <div><b>Send To</b>: {{emailview.record.send_to}}</div>
           <div><b>Status</b>: {{emailview.record.status}}</div>
           <div><b>Template name</b>: {{emailview.record.template_name}}</div>
           <div><b>Subject</b>: {{emailview.record.subject}}</div>
           </div>
           <div style="width: 100%; max-height: 400px; overflow: auto; border-top: 1px solid #F0F0F0" v-html="emailview.record.message_body">

           </div>
         </div>
      </template>
      <template v-slot:footer >
        <button  @click="$modal.hide(modalId)" class="button-full"><i class="fa fa-window-close"></i> Close</button>
      </template>
    </ModalForm>
    <Preloader v-if="loading"></Preloader>
    <SomethingWrong v-if="error !== '' && !loading" :error-message="error"/>
    <div class="row" v-if="!loading && error === ''">
      <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
        <Card :name="$t('general_info')" :show="true">
          <form class="container-fluid" style="" @submit="updateClient" onsubmit="return false">
            <div class="form-group row">
              <label class="col-12 col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('uuid') }}</label>
              <div class="col-12 col-md-9 col-sm-9">
                <b>{{data.uuid}}</b>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('name') }}</label>
              <div class="col-12 col-md-9 col-sm-9">
                <input type="text" v-model="data.name"
                       class="form-control" :placeholder="$t('name')">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12  col-md-3 col-sm-3 text-right control-label col-form-label">{{
                  $t('company_name')
                }}</label>
              <div class="col-12  col-md-9 col-sm-9">
                <input type="text" v-model="data.company_name"
                       class="form-control" :placeholder="$t('company_name')">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('email') }}</label>
              <div class="col-12 col-md-9 col-sm-9">
                <input type="email" v-model="data.email"
                       class="form-control" :placeholder="$t('email')">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('phone') }}</label>
              <div class="col-12 col-md-9 col-sm-9">
                <input type="text" v-model="data.phone"
                       class="form-control" :placeholder="$t('phone')">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-md-3 col-sm-3 text-right control-label col-form-label">{{
                  $t('password')
                }}</label>
              <div class="col-12 col-md-9 col-sm-9">
                <input v-model="data.password"
                       class="form-control" :placeholder="$t('password')">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-md-3 col-sm-3 text-right control-label col-form-label">{{
                  $t('discount')
                }}</label>
              <div class="col-12 col-md-9 col-sm-9">
                <input v-model="data.discount" type="number"
                       class="form-control" :placeholder="$t('discount')">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-md-3 col-sm-3 text-right control-label col-form-label">{{
                  $t('status')
                }}</label>
              <div class="col-12 col-md-9 col-sm-9">

                <v-select class="style-chooser"
                          v-model="data.status" label="name" :reduce="s => s.key" :clearable="false" :searchable="false"
                          :options="client_statuses"></v-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-md-3 col-sm-3 text-right control-label col-form-label">{{
                  $t('bill_enabled')
                }}</label>
              <div class="col-12 col-md-9 col-sm-9">

                <input type="checkbox" v-model="data.bill_enabled" style="height: 26px; width: 26px">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-md-3 col-sm-3 text-right control-label col-form-label">{{
                  $t('balance')
                }}</label>
              <div class="col-7 col-md-6 col-sm-6">
                <input type="number" step="0.01" v-model="data.balance" :disabled="!changingBalanceEnabled"
                       class="form-control" :placeholder="$t('balance')">
              </div>
              <div class="col-5 col-md-3 col-sm-3">
                <button @click="changingBalanceEnabled = !changingBalanceEnabled" onclick="return false"
                        class="btn btn-block btn-default">
                  <i class="mdi mdi-pencil" v-if="!changingBalanceEnabled"></i>
                  <i class="mdi mdi-pencil-off" v-if="changingBalanceEnabled"></i>
                </button>
              </div>
            </div>
            <div class="row">
              <div
                  class="col-12 offset-sm-6 col-sm-6  offset-md-6 col-md-6  offset-lg-6 col-lg-6  offset-xl-6 col-xl-6">
                <button class="btn btn-block btn-default"><i class="mdi mdi-content-save"></i> {{ $t('save') }}</button>
              </div>
            </div>
          </form>
        </Card>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
        <Card :name="$t('agents')" :show="true">
          <div class="row" v-if="data.agents === null">
            <div class="col-sm-6"><h4 style="text-align: center">{{ $t('agents_not_found') }}</h4></div>
          </div>
          <div v-if="data.agents !== null">
            <router-link :to="{name: 'agent_edit', params:{id: agent.id}}" v-for="agent in data.agents"
                         :key="agent.id">
              <div class="row agent-link">
                <div class="col-12">
                  <small>{{ agent.uuid }} ({{ agent.id }})</small>
                </div>
                <div class="col-6" v-if="agent.ip_address !== null">
                  {{ agent.ip_address }}
                </div>
                <div class="col-6" v-else>
                  {{ $t('not_registered') }}
                </div>
                <div class="col-6" v-if="agent.last_active !== null">
                  {{ moment(agent.last_active).locale($t('lang_short')).fromNow() }}
                  <small> ({{ $helpers.formatTime(agent.last_active) }})</small>
                </div>
              </div>
            </router-link>
          </div>
          <div class="row">
            <div class="col-sm-12 col-12" style="margin-top: 20px">
              <button class="btn btn-default btn-block" @click="createAgent">{{ $t('add_agent') }}</button>
            </div>
          </div>
        </Card>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
        <Card :name="$t('email_queue')" :show="true">
          <div style="max-height: 500px; overflow-y: auto">
            <table class="table table-sm table-bordered">
              <thead>
                <tr>
                   <th>ID</th>
                   <th>Send At</th>
                   <th>Send To</th>
                   <th>Template name</th>
                   <th>Status</th>
                   <th>View</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="list in emails" :key="list.id">
                  <td>{{list.id}}</td>
                  <td>{{$helpers.formatTime(list.send_at)}}</td>
                  <td>{{list.send_to}}</td>
                  <td>{{list.template_name}}</td>
                  <td>{{list.status}}</td>
                  <td><button class="btn btn-sm btn-default" @click="viewEmailInfo(list)"><i class="mdi mdi-eye"></i> </button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </Card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <Card :name="$t('payments')" :show="true">
          <div class="row">
            <div class="col-12">
              <button class="btn btn-default" @click="showAddingPaymentWindow" style="float: right"><i class="mdi mdi-plus"></i>
                {{ $t('add_payment') }}
              </button>
              <v-client-table ref="tbl" :columns="table.columns" :options="table.options" v-model="payments.list"
                              class="btn-add-margin-top-for-mobile table-sm">
                <template v-slot:created_at="{row}">{{ $helpers.formatTime(row.created_at) }}</template>
                <template v-slot:actions="{row}">
                    <div>
                      <button class="btn btn-sm btn-info" @click="editPayment(row)"><i class="mdi mdi-comment-edit"></i></button>
                      <button class="btn btn-sm btn-danger" @click="deletePayment(row)"><i class="mdi mdi-delete"></i></button>
                    </div>
                </template>

              </v-client-table>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>


<script>
import Preloader from "../../components/Helpers/Preloader";
import SomethingWrong from "../../components/Helpers/SomethingWrong";
import Card from "../../components/Helpers/Card";
import ModalForm from "@/components/Helpers/ModalForm.vue";

export default {
  components: {ModalForm, Card, SomethingWrong, Preloader},
  data() {
    return {
      emailview: {
        disable_btn: false,
        title: '',
        record: null,
      },
      emails: [],
      payments: {
        list: [],
        choosed: null,
        disableBtn: false,
        statuses: ['DECLINED', 'APPROVED', 'CREATED'],
        payment_type: ['DEPOSIT', 'PRIVILEGE', 'COMPENSATION'],
      },
      table: {
        columns: ['id', 'created_at', 'payment_type', 'order_description', 'amount', 'status', 'actions'],
        options: {
          headings: {
            id: 'Id',
            created_at: this.$t('created_at'),
            payment_type: this.$t('payment_type'),
            order_description: this.$t('order_description'),
            amount: this.$t('amount'),
            status: this.$t('status'),
            actions: this.$t('actions'),
          },
          perPage: 30,
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table',
          sortable: [],
          filterable: true,
          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
      changingBalanceEnabled: false,
      clientId: 0,
      data: {},
      savingInProcess: false,
      loading: true,
      error: '',
      client_statuses: [
        {key: 'ACTIVE', name: this.$t('client_statuses.ACTIVE')},
        {key: 'NOT_CONFIRMED', name: this.$t('client_statuses.NOT_CONFIRMED')},
        {key: 'DEACTIVATED', name: this.$t('client_statuses.DEACTIVATED')},
      ]
    }
  },
  async mounted() {
    this.loading = true
    this.clientId = this.$route.params.id
    this.loadEmails()
    await this.load()
  },
  watch: {},
  methods: {
    showAddingPaymentWindow() {
      this.payments.choosed = {
        id: null,
        client: this.data,
        order_description: '',
        amount: 0,
        status: 'APPROVED',
        payment_type: 'DEPOSIT',
      }
      this.$refs.paymentModal.show()
    },
    editPayment(payment) {
      this.payments.choosed = payment
      this.$refs.paymentModal.show()
    },
    deletePayment(payment) {
      this.$api.delete('/admin/payment/' + payment.id).then(() => {
        this.$refs.paymentModal.hide()
        this.$noty.success(this.$t('payment_success_deleted'))
        this.load()
      }).finally(() => {
        this.payments.disableBtn = false
      })
    },
    savePayment() {
      this.payments.disableBtn = true
      let payment = this.payments.choosed
      payment.amount = parseFloat(payment.amount)
      if(payment.id === null) {
        this.$api.post('/admin/payment', payment).then(r => {
          this.$noty.success(this.$t('payment_success_added'))
          this.load()
          this.$refs.paymentModal.hide()
        }).finally(() => {
          this.payments.disableBtn = false
        })
      } else {
        this.$api.put('/admin/payment/' + payment.id, payment).then(() => {
          this.$refs.paymentModal.hide()
          this.$noty.success(this.$t('payment_success_updated'))
        }).finally(() => {
          this.payments.disableBtn = false
        })
      }
    },
    loadEmails() {
      this.emails = []
      this.$api.get(`/admin/email-queue/by-client/${this.clientId}`).then(r => {
        this.emails = r.data
      }).catch( e => {
        alert("Error loading emails")
      })
    },
    loadPayments() {
      this.payments.list = []
      this.$api.get(`/admin/payment/by-client/${this.clientId}`).then(r => {
        this.payments.list = r.data
      }).catch( e => {
        alert("Error loading payments")
      })
    },
    viewEmailInfo(record) {
      this.emailview.record = record
      this.emailview.title = `#${record.id} - ${record.subject}`
      this.$refs.viewEmailInfo.show()
    },

    setClient(resp) {
      this.data = resp
    },
    createAgent(slf) {
      slf.target.setAttribute('disabled', '')
      this.$api.post('/admin/agent', {
        client: {
          id: Number(this.clientId),
        }
      }).then(() => {
        this.$noty.success(this.$t('agent_success_added'))
        this.load()
      }).finally(() => {
        slf.target.removeAttribute('disabled')
      })
    },
    async updateClient() {
      this.savingInProcess = true
      let data = {
        name: this.data.name,
        company_name: this.data.company_name,
        phone: this.data.phone,
        email: this.data.email,
        balance: parseFloat(this.data.balance).toFixed(4) * 1,
        status: this.data.status,
        bill_enabled: this.data.bill_enabled,
        discount: this.data.discount,
      }
      if (this.data.password !== "") {
        data.password = this.data.password
      }
      await this.$api.put('/admin/client/' + this.clientId, data).then(data => {
        this.setClient(data.data)
        this.savingInProcess = false
        this.$noty.success(this.$t('client_successful_updated', {name: this.data.login}))
      }).catch(() => {
      })
      this.savingInProcess = false
    },
    async load() {
      this.loading = true
      await this.$api.get('/admin/client/' + this.clientId).then(data => {
        this.setClient(data.data)
        this.$setRouteMeta(this.$t('edit_client', {name: data.data.name}))
        this.loadPayments()
      }).catch(() => {
        this.error = 'ERROR'
      })
      this.loading = false
    },
  }
}
</script>

<style scoped>
.agent-link {
  border-bottom: 1px solid #F0F0F0;
  padding: 5px;
  color: black;
}

.agent-link:hover {
  background: #F0F0F0;
  color: darkgreen;
  border-radius: 5px;
}
.button-full {
  border: 0;
  background-color: #FAFAFA;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 5px;
  border-top: 1px solid #DADADA;
  border-right: 1px solid #DADADA;
  color: #3e5569;
}
</style>