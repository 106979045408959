<template>
  <div class="container-fluid">
    <div class="row" v-if="loading && error === ''">
      <div class="col-12">
        <Preloader/>
      </div>
    </div>
    <div class="row" v-if="error !== ''">
      <div class="col-12">
        <SomethingWrong/>
      </div>
    </div>
    <ModalForm :disabled-buttons="record.disableButtons" :title="record.title" ref="editRecord" @save="saveRecord">
      <template v-slot:content>
        <form class="container" style="padding: 20px">
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('key') }}</label>
            <div class="col-md-9 col-sm-9">
              <input type="text" v-model="record.key"
                     class="form-control" :placeholder="$t('key')">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('archive_name') }}</label>
            <div class="col-md-9 col-sm-9">
              <input type="text" v-model="record.archive_name"
                     class="form-control" :placeholder="$t('archive_name')">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('dependencies') }}</label>
            <div class="col-md-9 col-sm-9">
              <v-select class="style-chooser" :close-on-select="false"
                        multiple
                        v-model="record.dependencies"  :options="displayDependencies"  ></v-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('description') }}</label>
            <div class="col-md-9 col-sm-9">
              <input type="text" v-model="record.description"
                     class="form-control" :placeholder="$t('description')">
            </div>
          </div>
        </form>
      </template>
    </ModalForm>
    <div class="row" v-if="!loading && error === ''">
      <div class="col-12">
        <div v-cloak>
          <button class="btn btn-default" @click="addRecord" style="float: right; margin-bottom: 10px"><i
              class="fa fa-plus"></i> {{ $t('create_new_component') }}
          </button>
          <v-client-table ref="table" v-model="data" :columns="table.columns" :options="table.options"
                          style="margin-top: 5px; ">
            <template v-slot:created_at="{row}">{{ $helpers.formatTime(row.created_at) }}</template>
            <template v-slot:updated_at="{row}">{{ $helpers.formatTime(row.updated_at) }}</template>
            <template v-slot:key="{row}">
              <span style="font-weight: bold">{{ row.key }}</span>
            </template>
            <template v-slot:archive_name="{row}">
              <span>{{ row.archive_name }}</span>
            </template>
            <template v-slot:description="{row}">
              <span>{{ row.description }}</span>
            </template>
            <template v-slot:actions="{row}">
              <div>
                <button @click="editRecord(row)" class="btn btn-default" style="margin: 3px" :title="$t('edit')"><i
                    class="fa fa-edit"></i></button>
                <button @click="deleteRecord(row.id)" class="btn btn-danger" style="margin: 3px" :title="$t('delete')">
                  <i class="fa fa-trash"></i></button>
              </div>
            </template>
          </v-client-table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Preloader from "../../components/Helpers/Preloader";
import SomethingWrong from "../../components/Helpers/SomethingWrong";
import ModalForm from "../../components/Helpers/ModalForm";

const url = '/admin/component'

export default {
  components: {ModalForm, SomethingWrong, Preloader},
  data() {
    return {
      data: [],
      record: {
        id: 0,
        key: "",
        archive_name: '',
        description: '',
        disableButtons: false,
        dependencies: [],
      },
      table: {
        columns: ['id', 'created_at', 'updated_at', 'key', 'archive_name', 'description', 'actions'],
        options: {
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table',
          headings: {
            id: 'Id',
            key: this.$t('key'),
            archive_name: this.$t('archive_name'),
            description: this.$t('description'),
            actions: '',
          },
          sortable: ['id', 'key', 'description'],
          filterable: ['key', 'description'],
          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
      loading: true,
      error: '',
    }
  },
  mounted() {
    this.$setRouteMeta(this.$t('components_management'))
    this.load()
  },
  computed: {
    displayDependencies() {
      let dependencies = []
      this.data.forEach(c => {
        if (c.key === this.record.key) return;
        if (this.record.dependencies.indexOf(c.key) === -1) {
          dependencies.push(c.key)
        }
      })
      return dependencies
    }
  },
  methods: {
    deleteRecord(id) {
      if (confirm(this.$t("are_you_sure_for_delete_record"))) {
        this.loading = true
        this.$api.delete(url + '/' + id).then(() => {
          this.$noty.success(this.$t('record_success_deleted'))
          this.load()
        })
      }
    },
    saveRecord() {
      this.record.disableButtons = true
      if (this.record.id !== 0) {
        this.$api.put(url + '/' + this.record.id, {
          key: this.record.key,
          description: this.record.description,
          archive_name: this.record.archive_name,
          dependencies: this.record.dependencies,
        }).then(() => {
          this.load()
          this.$noty.success(this.$t('record_success_updated'))
          this.$refs.editRecord.hide()
        }).finally(() => {
          this.record.disableButtons = false
        })
      } else {
        this.$api.post(url, {
          key: this.record.key,
          description: this.record.description,
          archive_name: this.record.archive_name,
          dependencies: this.record.dependencies,
        }).then(() => {
          this.load()
          this.$noty.success(this.$t('record_success_created'))
          this.$refs.editRecord.hide()
        }).finally(() => {
          this.record.disableButtons = false
        })
      }
    },
    addRecord() {
      this.record = {
        title: this.$t('create_new_record'),
        disableButtons: false,
        id: 0,
        key: "",
        archive_name: '',
        description: '',
        dependencies: [],
      }
      this.$refs.editRecord.show()
    },
    editRecord(record) {
      this.record = {
        title: this.$t('edit_record_with_key', {id: record.key}),
        disableButtons: false,
        id: record.id,
        key: record.key,
        archive_name: record.archive_name,
        description: record.description,
        dependencies: record.dependencies,
      }
      this.$refs.editRecord.show()
    },
    tableData(dt) {
      var data = []
      dt.forEach(e => {
        data.push({
          disableButtons: false,
          title: this.$t('edit_record_with_key', {id: e.key}),
          id: e.id,
          created_at: e.created_at,
          updated_at: e.updated_at,
          key: e.key,
          archive_name: e.archive_name,
          description: e.description,
          dependencies: e.dependencies == null ? [] : e.dependencies,
        })
      })
      return data
    },
    async load() {
      this.loading = true
      await this.$api.get(url).then(data => {
        this.data = this.tableData(data.data)
      }).catch(() => {
        this.error = 'ERROR'
      })
      this.loading = false
    },
  }
}
</script>

<style>
#app {
  width: 95%;
  margin: 0 auto;
}

.VuePagination {
  text-align: center;
}

.vue-title {
  text-align: center;
  margin-bottom: 10px;
}

.vue-pagination-ad {
  text-align: center;
}

.glyphicon.glyphicon-eye-open {
  width: 16px;
  display: block;
  margin: 0 auto;
}

th:nth-child(3) {
  text-align: center;
}

.VueTables__child-row-toggler {
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}

.VueTables__child-row-toggler--closed::before {
  content: "+";
}

.VueTables__child-row-toggler--open::before {
  content: "-";
}

[v-cloak] {
  display: none;
}

</style>