export default class Auth {
    /**
     * @private ServerApi
     */
    _http
    _auth

    constructor(http) {
        this._http = http
        let auth = JSON.parse(localStorage.getItem('local_auth_data'))
        if (!auth) {
            this._auth = this.getEmpty()
        } else {
            this._auth = auth
        }
    }

    isLogged() {
        if (this._auth.key !== null) return true;
    }

    _setData(auth = null) {
        if (auth != null) {
            this._auth = auth
        }
        localStorage.setItem('local_auth_data', JSON.stringify(this._auth))
    }

    login(username, password) {
        return this._http.post("/admin/auth/sign-in", {
            username: username,
            password: password,
        }, true).then(async r =>  {
            this._http.setToken(r.data)
            if (r.status !== 200) {
                throw Error("Incorrect auth")
            }
            this._auth.key = r.data
            await this._http.get('/admin/user/self').then(d => {
                this._auth.user = d.data
            })
            this._setData()
            return r.data
        })
    }

    getAuthKey() {
        return this._auth.key
    }

    getUser() {
        return this._auth.user
    }

    setUser(user) {
        this._auth.user = user
        this._setData()
        return this
    }

    setAuthKey(authKey) {
        this._auth.key = authKey
        this._setData()
        return this
    }
    isPermitted(permissionName) {
        return true
    }

    logout() {
        this._http.delete('/logout').then(() => {
        }).catch()
        this._setData(this.getEmpty())
    }

    getEmpty() {
        return JSON.parse(JSON.stringify({
            key: null,
            settings: {},
            user: {
                id: null,
                name: null,
                username: null,
                role: null,
            }
        }))
    }
}
