var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card "},[_c('div',{staticClass:"row",staticStyle:{"margin":"10px","margin-bottom":"5px"}},[_c('div',{staticClass:"col-sm-10"},[_c('h6',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.$t('releases')))])]),_c('div',{staticClass:"col-sm-2"},[_c('button',{staticClass:"btn btn-outline-info btn-block",on:{"click":function($event){return _vm.$router.push({name: 'releases'})}}},[_c('i',{staticClass:"fa fa-arrow-right"})])])]),_c('v-client-table',{ref:"table",staticStyle:{"margin-top":"5px"},attrs:{"columns":_vm.table.columns,"options":_vm.table.options},scopedSlots:_vm._u([{key:"created_at",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.$helpers.formatTime(row.created_at)))]}},{key:"updated_at",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.$helpers.formatTime(row.updated_at)))]}},{key:"version",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.version))])]}},{key:"change_log",fn:function(ref){
var row = ref.row;
return [_c('pre',{staticStyle:{"max-height":"150px","margin":"-10px","padding":"0"}},[_vm._v(_vm._s(row.change_log))])]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('button',{staticClass:"btn btn-default",staticStyle:{"margin":"3px"},attrs:{"title":_vm.$t('edit')},on:{"click":function($event){return _vm.editRecord(row)}}},[_c('i',{staticClass:"fa fa-edit"})]),_c('button',{staticClass:"btn btn-danger",staticStyle:{"margin":"3px"},attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.deleteRecord(row.id)}}},[_c('i',{staticClass:"fa fa-trash"})])])]}}]),model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }