<template>
  <div class="container-fluid">
    <div class="row" v-if="loading && error === ''">
      <div class="col-12">
        <Preloader/>
      </div>
    </div>
    <div class="row" v-if="error !== ''">
      <div class="col-12">
        <SomethingWrong/>
      </div>
    </div>
    <div class="row" v-if="!loading && error === ''">
      <div class="col-12">
        <button v-if="$auth.isPermitted('user_management')" class="btn btn-default" @click="$router.push({name: 'management_user_edit', params: {id: 'new'}})" style="float: right"><i class="fa fa-user-plus"></i> {{$t('create_new_user_btn')}}</button>
        <div v-cloak>
        <v-client-table v-model="data" :columns="table.columns" :options="table.options" class="btn-add-margin-top-for-mobile">
          <template v-slot:created_at="{row}">{{$helpers.formatTime(row.created_at)}}</template>
          <template v-slot:updated_at="{row}">{{$helpers.formatTime(row.updated_at)}}</template>
          <template v-slot:actions="{ row }" >
            <div v-if="$auth.isPermitted('user_management')">
            <router-link :to="{name: 'management_user_edit', params: {id:row.id}}" class="btn btn-default" style="margin: 3px" :title="$t('edit')"><i class="fa fa-edit"></i></router-link>
            <button :disabled="$auth.getUser().id === row.id || row.id <= 0" @click="deleteAccount(row.id)" class="btn btn-danger" style="margin: 3px" :title="$t('delete')"><i class="fa fa-trash"></i></button>
            </div>
          </template>
        </v-client-table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Preloader from "../../components/Helpers/Preloader";
import SomethingWrong from "../../components/Helpers/SomethingWrong";
export default {
  components: {SomethingWrong, Preloader},
  data() {
    return {
      data: [],
      table:  {
        columns: ['id','created_at', 'updated_at', 'username',  'email', 'role'],
        options: {
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table',
          headings: {
            id: 'Id',
            created_at: this.$t('created_at'),
            updated_at: this.$t('updated_at'),
            username: this.$t('login'),
            email: this.$t('email'),
            role: this.$t('role'),
            actions: '',
          },
          sortable: ['id','created_at', 'updated_at', 'username',  'email'],
          filterable: ['username', 'email'],

          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
      loading: true,
      error: '',
    }
  },
  mounted() {
    this.$setRouteMeta(this.$t('user_list'))
    if(this.$auth.isPermitted('user_management')) {
      this.table.columns.push('actions')
    }
    this.load()
  },
  methods: {
    deleteAccount(userId) {
      if(confirm(this.$t("are_you_sure_for_delete_user"))) {
        this.loading = true
        this.$api.delete('/admin/user/' + userId).then(() => {
          this.$noty.success(this.$t('user_success_deleted'))
          this.load()
        })
      }
    },
    tableData(dt) {
      var data = []
      dt.forEach(e => {
        data.push({
          id: e.id,
          created_at: e.created_at,
          updated_at: e.updated_at,
          username: e.username,
          email: e.email,
          role: e.role,
        })
      })
      return data
    },
    async load() {
      this.loading = true
      await this.$api.get('/admin/user').then(data => {
        this.data = this.tableData(data.data)
      }).catch(() => {
        this.error = 'ERROR'
      })
      this.loading = false
    },
  }
}
</script>
