<template>
  <nav class="sidebar-nav">
    <ul id="sidebarnav" class="p-t-30">
      <li :class="'sidebar-item' + (menu.active || menu.children.filter(e => {return e.active;}).length !== 0 ? ' selected' : '' )"
          v-for="(menu,k) in menus" :key="k">
        <a @click="changeActive(menu)" v-if="menu.display && menu.children.length === 0"
           class=" waves-effect  sidebar-link" aria-expanded="false">
          <i :class="menu.icon"></i>
          <span class="hide-menu">{{ $t(menu.name) }}</span>
        </a>
        <a v-if="menu.display &&  menu.children.length !== 0"
           :class="'sidebar-link has-arrow waves-effect waves-dark' + (menu.active || menu.children.filter(e => {return e.active;}).length !== 0 ? ' active' : '' )"
           @click="changeActive(menu)" aria-expanded="false">
          <i :class="menu.icon"></i>
          <span class="hide-menu">{{ $t(menu.name) }}</span>
        </a>
        <ul v-if="menu.display && menu.children.length !== 0" aria-expanded="false"
            :class="'collapse  first-level' +  (menu.active || menu.children.filter(e => {return e.active;}).length !== 0 ? ' in' : '' )">
          <li class="sidebar-item" v-for="(child, id) in (menu.children === null ? [] : menu.children)" :key="id">
            <a href="javascript:void(0)" @click="goToRoute(child.url)"
               :class="'sidebar-link' +  (child.active ? ' active' : '' )" v-if="child.display">
              <i :class="child.icon"></i>
              <span class="hide-menu">{{ $t(child.name) }}</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {menus: []};
  },
  methods: {
    recalcMenu() {
      //Filter menu
      let menus = this.$config.menu
      menus.forEach((e, k) => {
            menus[k].active = false;
            if (e.children !== null && e.url === null) {
              var countDisplayed = 0
              e.children.forEach((child, childKey) => {
                    var permitted = true;
                    if (typeof child.need_permissions !== 'undefined' && child.need_permissions !== null) {
                      child.need_permissions.forEach(perm => {
                        if (!this.$auth.isPermitted(perm)) {
                          permitted = false
                        }
                      })
                    }
                    //Нужно удалить
                    if (!permitted) {
                      menus[k].children[childKey].display = false
                    } else {
                      menus[k].children[childKey].display = true
                      countDisplayed++
                    }
                    menus[k].children[childKey].active = false;
                  }
              )
              menus[k].display = countDisplayed !== 0;
            } else {
              menus[k].children = [];
              var display = true
              if (typeof e.need_permissions !== "undefined" && e.need_permissions !== null) {
                e.need_permissions.forEach(perm => {
                  if (!this.$auth.isPermitted(perm)) {
                    display = false
                  }
                })
              }
              menus[k].display = display
              menus[k].active = false;
            }
          }
      )
      this.menus = menus
      this.findRoute()
    },
    findRoute() {
      this.$route.matched.forEach(matched => {
        this.menus.forEach(root => {
          if (root.url === matched.path) {
            root.active = true
          }
          if (root.children !== null && root.children.length !== 0) {
            root.children.forEach(child => {
              if (matched.path === child.url) {
                child.active = true
              }
            })
          }
        })
      })

    },
    hideMenuOnMobile() {
      let element = document.getElementById('main-wrapper');
      let toggler = document.getElementById('ti-menu-toggler');
      if (window.innerWidth < 760) {
        toggler.className = 'ti-close ti-menu'
        element.className = ''
      }
    },
    goToRoute(url) {
      this.hideMenuOnMobile()
      this.$router.push(url)
    },
    changeActive(menu) {
      let changeTo = !menu.active
      this.menus.forEach((e) => {
        e.active = false
        if (e.children !== null && e.children.length !== 0) {
          e.children.forEach(child => {
            child.active = false
          })
        }
      })

      if (menu.url !== null) {
        this.goToRoute(menu.url)
      }
      if (menu.children !== null && menu.children.length !== 0) {
        menu.active = changeTo
      } else {
        menu.active = true
      }
    }
  },
  mounted() {
    this.recalcMenu()
  }
}
</script>
