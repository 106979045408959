<template>
  <div>
    <div class="card ">
      <div class="card-header">
        Top agents by devices
      </div>
      <div class="card-body" style="padding: 0">
      <table class="table table-sm table-bordered">
        <thead>
        <tr>
          <th>Agent</th>
          <th>Count devices</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(dt, k) in list" :key="k">
          <td style="min-width: 150px"><router-link target="_blank" :to="{name: 'agent_edit', params: {id: dt.agent.id}}"><small>{{dt.agent.ip_address}}</small></router-link></td>
          <td>{{dt.count_devices}}</td>
        </tr>
        </tbody>
      </table>
      </div>
    </div>
  </div>
</template>


<script>

const url = '/admin/release'

export default {
  props: {
    list: {
      type: Array,
    },
  },
  watch: {
    list: {
      handler(n) {
        console.log(n)
      }
    }
  },


}
</script>