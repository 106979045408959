import moment from "moment/moment";

export default {
    validateIPaddress(ipaddress) {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
            return true
        }
        return false
    },
    formatTime(time) {
        let date = new Date(time)
        return moment(date).format("Y-MM-DD HH:mm:ss")
    },
    isMobile() {
        return window.innerWidth < 576
    },
    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 B';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['B', 'K', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        if(typeof sizes[i] === 'undefined') {
            return '0 B'
        }
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    formatBits(bytes, decimals = 2) {
        if (bytes === 0) return '0 B';
        const k = 1000;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['B', 'K', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        if(typeof sizes[i] === 'undefined') {
            return '0 B'
        }
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    formatByteRates(bytes, decimals = 2) {
        if (bytes === 0) return '0 b/s';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['b/s', 'Kb/s', 'Mb/s', 'Gb/s', 'Tb/s', 'Pb/s', 'EB/s', 'ZB/s', 'YB/s'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        if(typeof sizes[i] === 'undefined') {
            return '0 b/s';
        }
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    formatBitsRates(bits, decimals = 2) {
        if (bits === 0) return '0 b/s';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['bits', 'Kbits', 'Mbits', 'Gbits', 'Tbits', 'Pbits', 'EBits', 'ZBits', 'YBits'];
        const i = Math.floor(Math.log(bits) / Math.log(k));
        if(typeof sizes[i] === 'undefined') {
            return '0 bits';
        }
        return parseFloat((bits / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    formatPktsRates(pkts, decimals = 2) {
        if (pkts === 0) return '0 bps';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps', 'EBps', 'ZBps', 'YBps'];
        const i = Math.floor(Math.log(pkts) / Math.log(k));
        if(typeof sizes[i] === 'undefined') {
            return '0 bps';
        }
        return parseFloat((pkts / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
}
