<template>
  <div id="main-wrapper" :class="mainWrapperClass" data-sidebartype="mini-sidebar" >
    <Preloader :with-text="true" v-show="prealoader" style="position: fixed; top: 0; left: 0; z-index: 999; background: rgba(255, 255, 255, 1);"/>
    <Topbar v-show="!loading"/>
    <LeftSideBar v-show="!prealoader" :is-loaded="!loading" v-click-outside="hideSideBar" />
    <PageWrapper v-if="!loading" />
    <BigNotify ref="notify" :hide-on-click="true" :timeout="0" v-show="!loading"></BigNotify>
    <to-top-btn></to-top-btn>
  </div>
</template>

<script>
import Topbar from "./Main/Topbar";
import LeftSideBar from "./Main/LeftSideBar";
import PageWrapper from "./Main/PageWrapper";
import BigNotify from "@/components/Helpers/BigNotify";
import defaults from "@/config/global";
import Preloader from "@/components/Helpers/Preloader";
import ToTopBtn from "@/components/Helpers/ToTopBtn";

export default {
  components: {
    Preloader,
    PageWrapper,
    LeftSideBar,
    Topbar,
    BigNotify,
    ToTopBtn
  },
  data() {
      return {
        loading: true,
        prealoader: true,
        isActiveSideBar: false,
        mainWrapperClass: "mini-sidebar",
      }
  },
  async mounted() {
    if (document.getElementById('custom-resource-js') === null) {
      let script = document.createElement('script')
      script.setAttribute('src', '/dist/js/custom.js')
      document.body.appendChild(script)
    }
    this.loading = false
    setTimeout(() => {
      this.prealoader = false
    }, 150)
  },
  watch: {},
  methods: {
    hideSideBar(click) {
     if(window.innerWidth > 756) {
       return;
     }
     if(typeof click.target.className.includes === 'function') {
       if (click.target.className.includes('nav-toggler')) {
         return
       }
       if (click.target.className.includes('ti-close')) {
         return
       }
     }
     document.getElementById('main-wrapper').setAttribute('class', '')
     document.getElementById('ti-menu-toggler').setAttribute('class', 'ti-close ti-menu')
    },

  }
}
</script>

<style>
</style>
