<template>
  <div class="" style="background-color: #000000; background-image: url(/assets/images/login_background.jpg); background-size: 100%; background-repeat: no-repeat;">
    <link href="/dist/css/style.min.css" rel="stylesheet">

    <div style="margin-top: 0 !important;" class="auth-wrapper d-flex  justify-content-center align-items-center">
      <div class="auth-box " style="background-color: #343a40">
        <div id="loginform">
          <div class="text-center p-t-20 p-b-20" style="font-size: 24pt; color: #FAFAFA;">
            <img style="text-align: center; width: 64px; height: 64px;" src="/assets/images/logo-icon.png"><br>
            {{$config.name}}
          </div>
          <!-- Form -->
          <form class="form-horizontal m-t-20" style="margin-top: 10px; margin-bottom: 10px" id="loginform" @submit.prevent="tryLogin">
            <div class="row p-b-30">
              <div class="col-12">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text bg-success text-white" id="basic-addon1"><i class="ti-user"></i></span>
                  </div>
                  <input :disabled="inProccess" type="text" v-model="login" class="form-control form-control-lg" :placeholder="$t('username')" aria-label="Username" aria-describedby="basic-addon1" required="">
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text bg-warning text-white" id="basic-addon2"><i class="ti-pencil"></i></span>
                  </div>
                  <input :disabled="inProccess" type="password" v-model="password"  class="form-control form-control-lg" :placeholder="$t('password')" aria-label="Password" aria-describedby="basic-addon1" required="">
                </div>
              </div>
            </div>
            <div class="row border-secondary">
              <div class="col-12">
                <div class="form-group">
                  <div class="p-t-10">
                   <!-- <button class="btn btn-info" id="to-recover" type="button"><i class="fa fa-lock m-r-5"></i> Lost password?</button> -->
                    <button class="btn btn-success float-right" :disabled="inProccess" type="submit" >{{$t('sign_in')}}  <i style="padding-left: 5px;" :class="'mdi ' + (inProccess ? ' mdi-spin mdi-loading ' : 'mdi-login')"></i> </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div id="recoverform">
          <div class="text-center">
            <span class="text-white">Enter your e-mail address below and we will send you instructions how to recover a password.</span>
          </div>
          <div class="row m-t-20">
            <!-- Form -->
            <form class="col-12" action="index.html">
              <!-- email -->
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-danger text-white" id="basic-addon1"><i class="ti-email"></i></span>
                </div>
                <input type="text" class="form-control form-control-lg" placeholder="Email Address" aria-label="Username" aria-describedby="basic-addon1">
              </div>
              <!-- pwd -->
              <div class="row m-t-20 p-t-20 border-top border-secondary">
                <div class="col-12">
                  <a class="btn btn-success" href="#" id="to-login" name="action">Back To Login</a>
                  <button class="btn btn-info float-right" type="button" name="action">Recover</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    let script = document.createElement('script')
    script.setAttribute('src', '/assets/auth.js')
    document.head.appendChild(script)
  },
  data() {
    return {
      inProccess: false,
      message: '',
      login: this.$auth.getUser().login,
      password: '',
    }
  },
  methods: {
    tryLogin() {
        this.inProccess = true
        console.log("Try login with " + this.login)
        this.$auth.login(this.login, this.password).then(r => {
          if(typeof this.$route.query.redirect !== 'undefined') {
            this.$router.push(this.$route.query.redirect)
          } else {
            this.$router.push('/')
          }
        }).catch(e => {
          console.log(e)
           if(e.response.status === 401 || e.response.status === 422) {
             this.$noty.error(this.$t('login_or_password_incorrect'))
           } else {
             this.$noty.error(this.$t('unknown_error'))
           }
        }).finally(() => {
          this.inProccess = false
        })
    }
  }
}
</script>

