<template>
  <modal :name="modalId" :adaptive="true" :height="'auto'"  :width="modalWidth" class="vm-modal">
    <div class="box-container">
      <div class="header">
        <slot name="header" >
          <div v-if="title !==''" style="padding: 10px; background: #FAFAFA; border-bottom: 1px solid #DADADA">
            <h4 style="margin: 0; padding: 0">{{ title }}</h4>
            <a href="javascript:void(0)" @click="$modal.hide(modalId); " style="position: absolute; right: 10px; top: 4px; font-weight: bold; color: darkred">
              <i class="mdi mdi-24px mdi-close"></i>
            </a>
          </div>
        </slot>
      </div>
      <div class="content">
        <slot name="content" >
          <h3 align="center">Modal CONTENT</h3>
        </slot>
      </div>
      <div class="footer">
        <slot name="footer">
          <button :disabled="disabledButtons" @click="$modal.hide(modalId)" class="button-close"><i class="fa fa-window-close"></i> {{ closeBtn }}</button>
          <button :disabled="disabledButtons" @click="$emit('save')" class="button-save"><i class="fa fa-save"></i> {{ actionBtn }}</button>
        </slot>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    disabledButtons: {
      type: Boolean,
      default: false,
    },
    actionBtnName: {
      type: String,
      default:  '',
    },
    closeBtnName: {
      type: String,
      default:  '',
    },
    modalWidth: {
      type: [Number, String],
      default: 600,
    },
    modalMinHeight: {
      type: [Number, String],
      default: 500,
    }
  },
  computed: {
    closeBtn() {
      if(this.closeBtnName !== '') return  this.closeBtnName
      return this.$t('close')
    },
    actionBtn() {
      if(this.actionBtnName !== '') return  this.actionBtnName
      return this.$t('save')
    }
  },
  data() {
    return {
      displayBlock: false,
      modalId: '',
    }
  },
  mounted() {
    this.modalId = this.makeid(10)
  },
  methods: {
    show() {
      this.$modal.show(this.modalId)
    },
    hide() {
      this.$modal.hide(this.modalId)
    },
    makeid(length) {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }
  }

}
</script>
<style scoped>
.button-close:disabled {
  border: 0;
  background-color: #EAEAEA;
  width: 50%;
  height: 100%;
  margin: 0;
  padding: 5px;
  border-top: 1px solid #DADADA;
  border-right: 1px solid #DADADA;
  color: #5c727f;
  outline: 0;
}
.button-save:disabled {
  border: 0;
  background-color: #EAEAEA;
  width: 50%;
  height: 100%;
  margin: 0;
  padding: 5px;
  border-top: 1px solid #DADADA;
  color: #5c727f;
  outline: 0;
}
.button-close {
  border: 0;
  background-color: #FAFAFA;
  width: 50%;
  height: 100%;
  margin: 0;
  padding: 5px;
  border-top: 1px solid #DADADA;
  border-right: 1px solid #DADADA;
  color: #3e5569;
}
.button-save {
  border: 0;
  background-color: #FAFAFA;
  width: 50%;
  height: 100%;
  margin: 0;
  padding: 5px;
  border-top: 1px solid #DADADA;
  color: #3e5569;
}
.button-close:focus {
  border: 0;
  background-color: #DADADA;
  width: 50%;
  height: 100%;
  margin: 0;
  padding: 5px;
  border-top: 1px solid #DADADA;
  border-right: 1px solid #DADADA;
  color: #3e5569;
  outline: 0;
}
.button-save:focus {
  border: 0;
  background-color: #DADADA;
  width: 50%;
  height: 100%;
  margin: 0;
  padding: 5px;
  border-top: 1px solid #DADADA;
  color: #3e5569;
  outline: 0;
}

</style>

<style scoped>

.dropdown-menu {
  background-color: black;
}

.box-container {
  display: flex;
  flex-direction: column;
}

.header {
  height: 44px;
}

.content {
  flex: 1 0 auto;/*Три*/
}

.footer {
  height: 40px;
  padding: 0;
  margin: 0;
}

</style>
