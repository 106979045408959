<template>
  <div>
    <div class="card ">
      <div class="card-header">
        Summary by versions
      </div>
      <div class="card-body" style="padding: 0; overflow-y: auto; max-height: 400px">
      <table class="table table-sm table-bordered">
        <thead>
        <tr>
          <th>Version</th>
          <th>Count agents</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(v) in list" :key="v.version">
          <td style="min-width: 150px"><b>{{v.version}}</b></td>
          <td>{{v.count}}</td>
        </tr>
        </tbody>
      </table>
      </div>
    </div>
  </div>
</template>


<script>

const url = '/admin/release'

export default {
  props: {
    list: {
      type: Array,
    },
  },
  watch: {
    list: {
      handler(n) {
        console.log(n)
      }
    }
  },

}
</script>