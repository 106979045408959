<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
        error: '',
        loading: false,
    }
  },
  watch: {

  },
}
</script>

<style>

.vm--modal {
  overflow: visible !important;
}

.bg-tab-white {
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
}
.bg-white-gradient {
  background: rgb(255,255,255);
  background: linear-gradient(335deg, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0) 56%);
}
#graph{
  position: relative;
  height: 100%;
  width: 100% !important;
}

canvas {
  width: 100% !important;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

.slide-enter-to, .slide-leave {
  max-height: 700px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}


.card {
  border-top: 1px solid #E0E0E0 !important;
  border-radius: 0px;
  border-left: 1px solid #E0E0E0 !important;
  border-right: 1px solid #E0E0E0 !important;
  border-bottom: 1px solid #E0E0E0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.a-btn {
  font-weight: bold !important;
  color: white !important;
  margin-left: 5px; font-size: 20px; padding: 2px 8px 0px  8px;
}
.a-btn:hover {
font-weight: bold !important;
color: white !important;
}
.a-btn:visited {
  font-weight: bold !important;
  color: white !important;
}


.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #FFF;
  border: 1px solid #e9ecef ;
  border-radius: 2px;
  padding-top: 2px;
  padding-left: 3px;
  padding-bottom: 5px;
  color: #394066;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #555;
}
.VueTables__search {
  width: 100%;
}
.VueTables__search-field {
  position: relative;
  right: 0;
}

.VueTables__search-field label {
  padding-right: 5px;
  display: inline !important;
}
.VueTables__limit-field {
  min-width: 150px;
  display: inline;
}
.VueTables__limit-field label {
  float: left;
}
.VueTables__limit-field select {
  width: 80px;
}
.VueTables__search__input {
  display: inline !important;

  min-width: 300px;
}
.styled-table {
  background-color: #FFFFFF;
}
.styled-table thead tr th {
  background-color: #343a40;
  color: #DADADA;
  border: 1px solid #1f2226;
}

.styled-table tbody tr td {
  border: 1px solid #dadada;
  border-left:  1px solid #dadada;
  border-right: 1px solid #dadada;
}

.table-full-width {
  width: 100%;

}
.table-without-outline {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 0;
}
.table-without-outline thead tr {
  border-bottom: 1px solid #DADADA;
}
.table-without-outline thead tr > th:nth-of-type(1)  {
  padding-left: 25px;
}
.table-without-outline thead tr > th:nth-last-of-type(1)  {
  padding-right: 25px;
}
.table-without-outline tbody tr > td:nth-of-type(1)  {
  padding-left: 25px;
}
.table-without-outline tbody tr > td:nth-last-of-type(1)  {
  padding-right: 25px;
}
.table-without-outline tbody tr {
  border-bottom: 1px solid #DADADA;
}
.table-without-outline thead tr th {
  padding: 5px;
}

@media only screen and (max-width: 580px) {
  .col-12,.col-1,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-10,.col-11 {
    padding: 1px !important;
  }
  .table-responsive table {
    win-width: 480px !important;
  }
  .btn-add-margin-top-for-mobile {
    margin-top: 55px;
  }
  .VueTables__search {
    display: inline !important;
    width: 100%;
  }
  .card {
    margin-top: -10px !important;
  }
}

.table-responsive {
  overflow-x: auto;
  scrollbar-width: thin;
}
.table-responsive table {
  min-width: 500px;
}
.btn-add-margin-top-for-mobile {

}
.custom-filter {
  min-width: 250px;
}
.srv-tbl-custom {
}


.VueTables__child-row-toggler--closed::before {
  content: "\21e2";
}

.VueTables__child-row-toggler--open::before {
  content: "\21E3";
}


#app {
  width: 95%;
  margin: 0 auto;
}

.VuePagination {
  text-align: center;
}

.vue-title {
  text-align: center;
  margin-bottom: 10px;
}

.vue-pagination-ad {
  text-align: center;
}

.glyphicon.glyphicon-eye-open {
  width: 16px;
  display: block;
  margin: 0 auto;
}

th:nth-child(3) {
  text-align: center;
}

.VueTables__child-row-toggler {
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}

.VueTables__child-row-toggler--closed::before {
  content: "+";
}

.VueTables__child-row-toggler--open::before {
  content: "-";
}

[v-cloak] {
  display: none;
}

</style>
