<template>
  <aside class="left-sidebar" data-sidebarbg="skin5">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
      <!-- Sidebar navigation-->
      <Navigation ref="nav" />
      <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
  </aside>
</template>

<script>
 import Navigation from "./LeftSideBar/Navigation";
 export default  {
   props: {
     isLoaded: {
       type: Boolean,
       default: false,
     }
   },
   components: {Navigation},
   watch: {
     isLoaded: {
       handler(n) {
         if(n) {
           this.$refs.nav.recalcMenu()
         }
       },
     }
   }
 }
</script>
