<template>
  <div class="container-fluid">
    <div class="row" v-if="loading && error === ''">
      <div class="col-12">
        <Preloader/>
      </div>
    </div>
    <div class="row" v-if="error !== ''">
      <div class="col-12">
        <SomethingWrong/>
      </div>
    </div>
    <ModalForm :disabled-buttons="record.disableButtons" :title="record.title" ref="editRecord" @save="saveRecord">
      <template v-slot:content>
        <form class="container" style="padding: 20px">
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('key') }}</label>
            <div class="col-md-9 col-sm-9">
              <input type="text" v-model="record.key"
                     class="form-control" :placeholder="$t('key')">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('name') }}</label>
            <div class="col-md-9 col-sm-9">
              <input type="text" v-model="record.name"
                     class="form-control" :placeholder="$t('name')">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('charging_amount') }}</label>
            <div class="col-md-9 col-sm-9">
              <table>
                <tr>
                  <td style="width: 50%">
                    <small>Default</small>
                    <input type="number" step="0.01" min="0" v-model="record.charging_amount"
                           class="form-control" :placeholder="$t('charging_amount')">
                  </td>
                  <td  style="width: 50%">
                    <small>By interface</small>
                    <input type="number" step="0.0001" min="0" v-model="record.charging_amount_by_interface"
                           class="form-control" :placeholder="$t('charging_amount_by_interface')">
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('type') }}</label>
            <div class="col-md-9 col-sm-9">
              <v-select class="style-chooser" :max-height="'100px'" :clearable="false" :searchable="false"
                        v-model="record.type" label="name" :reduce="e => e.key" :options="subscription_type" ></v-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('components') }}</label>
            <div class="col-md-9 col-sm-9">
              <v-select class="style-chooser" :close-on-select="false"
                        multiple

                        v-model="record.components"  :options="displayComponents" ></v-select>
            </div>
          </div>
        </form>
      </template>
    </ModalForm>
    <div class="row" v-if="!loading && error === ''">
      <div class="col-12">
        <div v-cloak>
          <button class="btn btn-default" @click="addRecord" style="float: right; margin-bottom: 10px"><i
              class="fa fa-plus"></i> {{ $t('create_new_subscription') }}
          </button>
          <v-client-table ref="table" v-model="data" :columns="table.columns" :options="table.options"
                          style="margin-top: 5px; " class="table table-sm">
            <template v-slot:created_at="{row}">{{ $helpers.formatTime(row.created_at) }}</template>
            <template v-slot:updated_at="{row}">{{ $helpers.formatTime(row.updated_at) }}</template>
            <template v-slot:subscription_type="{row}">{{ row.type }}</template>
            <template v-slot:key="{row}">
              <span style="font-weight: bold">{{ row.key }}</span>
            </template>
            <template v-slot:components="{row}">
              <div v-if="row.components !== null && row.components.length > 0" style="min-width: 190px; max-height: 200px; overflow: auto">
                <li v-for="component in row.components" :key="component">{{component}}</li>
              </div>
              <div v-else>{{$t('no_components')}}</div>
            </template>
            <template v-slot:actions="{row}">
              <div>
                <button @click="editRecord(row)" class="btn btn-default" style="margin: 3px" :title="$t('edit')"><i
                    class="fa fa-edit"></i></button>
                <button @click="deleteRecord(row.id)" class="btn btn-danger" style="margin: 3px" :title="$t('delete')">
                  <i class="fa fa-trash"></i></button>
              </div>
            </template>
          </v-client-table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
"use strict";
import Preloader from "../../components/Helpers/Preloader";
import SomethingWrong from "../../components/Helpers/SomethingWrong";
import ModalForm from "../../components/Helpers/ModalForm";

const url = '/admin/subscription'

export default {
  components: {ModalForm, SomethingWrong, Preloader},
  computed: {
    displayComponents() {

      let components = []
      this.components.forEach(c => {
        console.log("Check component " + c.key)
        if (this.record.components.indexOf(c.key) === -1) {
            components.push(c.key)
        }
      })
      console.log(components)
      return components
    }
  },
  data() {
    return {
      subscription_type: [
        {key: 'AGENT', name: this.$t('subscription_type.AGENT')},
        {key: 'COMPONENTS', name: this.$t('subscription_type.COMPONENTS')},
      ],
      components: [],
      data: [],
      record: {
        id: 0,
        created_at: '',
        updated_at: '',
        key: "",
        name: '',
        charging_amount: 0.0,
        charging_amount_by_interface: 0.0,
        type: 'AGENT',
        components: [],
      },
      watch: {
        record: {
          handler(n) {
            console.log(n)
          },
          deep: true,
        }
      },
      table: {
        columns: ['id', 'created_at', 'updated_at', 'key', 'name', 'charging_amount_by_interface', 'charging_amount', 'subscription_type', 'components', 'actions'],
        options: {
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table',
          headings: {
            id: 'Id',
            key: this.$t('key'),
            name: this.$t('name'),
            charging_amount_by_interface: this.$t('charging_amount_by_interface'),
            charging_amount: this.$t('charging_amount'),
            components: this.$t('components'),
            subscription_type: this.$t('subscription_type_name'),
            actions: '',
          },
          sortable: ['id', 'key', 'name'],
          filterable: ['key', 'name'],
          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
      loading: true,
      error: '',
    }
  },
  async mounted() {
    this.$setRouteMeta(this.$t('subscription_management'))
    this.loading = true
    this.components = await this.loadComponents()
    await this.load()
    if(typeof this.$route.params.id !== 'undefined') {
      console.log("ID for edit is defined")
      let record = null
      this.data.some(e => {
        if (e.id == this.$route.params.id) {
          record = e
          return true
        }
        return false
      })
      if (record == null) {
        this.$noty.error("Subscription with id = " +  this.$route.params.id + " not found")
      } else {
        this.editRecord(record)
      }

    }
   },
  methods: {
    deleteRecord(id) {
      if (confirm(this.$t("are_you_sure_for_delete_record"))) {
        this.loading = true
        this.$api.delete(url + '/' + id).then(() => {
          this.$noty.success(this.$t('record_success_deleted'))
          this.load()
        })
      }
    },
    saveRecord() {
      this.record.disableButtons = true
      if (this.record.id !== 0) {
        this.$api.put(url + '/' + this.record.id, {
          key: this.record.key,
          name: this.record.name,
          charging_amount_by_interface: parseFloat(this.record.charging_amount_by_interface).toFixed(4) * 1,
          charging_amount: parseFloat(this.record.charging_amount).toFixed(2) * 1,
          components: this.record.components,
          type: this.record.type,
        }).then(() => {
          this.load()
          this.$noty.success(this.$t('record_success_updated'))
          this.$refs.editRecord.hide()
        }).finally(() => {
          this.record.disableButtons = false
        })
      } else {
        this.$api.post(url, {
          key: this.record.key,
          name: this.record.name,
          charging_amount_by_interface: parseFloat(this.record.charging_amount_by_interface).toFixed(4) * 1,
          charging_amount: parseFloat(this.record.charging_amount).toFixed(2)  * 1,
          components: this.record.components,
          type: this.record.type,
        }).then(() => {
          this.load()
          this.$noty.success(this.$t('record_success_created'))
          this.$refs.editRecord.hide()
        }).finally(() => {
          this.record.disableButtons = false
        })
      }
    },
    addRecord() {
      this.record = {
        title: this.$t('create_new_record'),
        disableButtons: false,
        created_at: '',
        updated_at: '',
        id: 0,
        key: "",
        name: '',
        charging_amount: 0.0,
        charging_amount_by_interface: 0.0,
        components: [],
        type: 'AGENT',
      }
      this.$refs.editRecord.show()
    },
    editRecord(record) {
      this.record = {
        title: this.$t('edit_record_with_key', {id: record.key}),
        disableButtons: false,
        id: record.id,
        created_at: record.created_at,
        updated_at: record.updated_at,
        key: record.key,
        name: record.name,
        charging_amount_by_interface: record.charging_amount_by_interface,
        charging_amount: record.charging_amount,
        components: record.components,
        type: record.type,
      }
      this.$refs.editRecord.show()
    },
    tableData(dt) {
      var data = []
      dt.forEach(record => {
        data.push({
          disableButtons: false,
          title: this.$t('edit_record_with_key', {id: record.key}),
          created_at: record.created_at,
          updated_at: record.updated_at,
          id: record.id,
          key: record.key,
          name: record.name,
          charging_amount_by_interface: record.charging_amount_by_interface,
          charging_amount: record.charging_amount,
          components: record.components,
          type: record.type,
        })
      })
      return data
    },
    async load() {
      this.loading = true
      await this.$api.get(url).then(data => {
        this.data = this.tableData(data.data)
      }).catch(() => {
        this.error = 'ERROR'
      })
      this.loading = false
    },
    async loadComponents() {
       let components = []
       await this.$api.get('/admin/component').then(d => {
          components = d.data
       })
       return components
    }
  }
}
</script>

<style>

.vs__dropdown-menu {
  max-height: 250px !important;
}

</style>