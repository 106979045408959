<template>
  <div class="container-fluid">
    <div class="row" v-if="loading && error === ''">
      <div class="col-12">
        <Preloader/>
      </div>
    </div>
    <div class="row" v-if="error !== ''">
      <div class="col-12">
        <SomethingWrong/>
      </div>
    </div>
    <div class="row" v-if="!loading && error === ''">
      <div class="col-12 col-sm-12 col-lg-5 col-xl-4 col-md-12">
        <Card :name="$t('personal_info')" :show="true">
          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('username') }}</label>
            <div class="col-sm-9">
              <input  type="text" v-model="data.username"
                     class="form-control" :placeholder="$t('username')">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('email') }}</label>
            <div class="col-sm-9">
              <input   type="email" v-model="data.email"
                     class="form-control" :placeholder="$t('email')">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('role') }}</label>
            <div class="col-sm-9">
              <v-select class="style-chooser"
                        v-model="data.role" label="name" :reduce=" e => e.key" :options="roles"></v-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('new_password') }}</label>
            <div class="col-sm-9">
              <input style="vertical-align: bottom" v-model="data.password" type="password" class="form-control"
                     placeholder="*************">
            </div>
          </div>
          <div class="row">
            <hr style="margin-top: 20px; width: 100%">
            <div class="col-12">
              <button v-if="userId !== 0" class="btn btn-default" @click="updateUser" :disabled="savingInProcess"><i
                  class="fa fa-save"
                  style="padding-right: 4px; padding-top: 2px; padding-bottom: 2px; font-size: 16px"></i>
                {{ $t('save') }}
              </button>
              <button v-if="userId === 0" class="btn btn-default" @click="createUser" :disabled="savingInProcess"><i
                  class="fa fa-save"
                  style="padding-right: 4px; padding-top: 2px; padding-bottom: 2px; font-size: 16px"></i>
                {{ $t('create') }}
              </button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>


<script>
import Preloader from "../../components/Helpers/Preloader";
import SomethingWrong from "../../components/Helpers/SomethingWrong";
import Card from "../../components/Helpers/Card";

export default {
  components: {Card, SomethingWrong, Preloader},
  data() {
    return {
      userId: 0,
      data: {},
      roles: [
        {key: 'ADMIN',  name: this.$t('roles.ADMIN')},
        {key: 'USER',  name: this.$t('roles.USER')},
      ],
      savingInProcess: false,
      loading: true,
      error: '',
    }
  },
  async mounted() {
    this.loading = false
    if (this.$route.params.id === 'new') {
      this.$setRouteMeta(this.$t('create_new_user'))
      this.setUser({
        role: "ADMIN",
        username: '',
        email: '',
        password: '',
        id: 0,
      })
    } else {
      this.userId = this.$route.params.id
      await this.load()
    }
  },
  watch: {},
  methods: {
    setUser(resp) {
      this.data = resp
    },
    async updateUser() {
      this.savingInProcess = true
      let data = {
        role: this.data.role,
        username: this.data.username,
        email: this.data.email,
      }
      if (this.data.password !== "") {
        data.password = this.data.password
      }
      await this.$api.put('/admin/user/' + this.userId, data).then(data => {
        this.setUser(data.data)
        this.savingInProcess = false
        this.$noty.success(this.$t('user_successful_updated', {name: this.data.login}))
      }).catch(() => {
      })
      this.savingInProcess = false
    },
    async createUser() {
      this.savingInProcess = true
      this.$api.post('/admin/user', {
        role: this.data.role,
        username: this.data.username,
        email: this.data.email,
        password: this.data.password,
      }).then(data => {
        this.setUser(data.data)
        this.savingInProcess = false
        this.$noty.success(this.$t('user_successful_created', {name: this.data.username, id: data.data.id}))
        this.$router.push({name: 'management_user_edit', params: {id: data.data.id}})
        this.userId = data.data.id
        this.load()
      }).catch(() => {
        this.savingInProcess = false
      })
    },
    async load() {
      this.loading = true
      await this.$api.get('/admin/user/' + this.userId).then(data => {
        this.setUser(data.data)
        this.$setRouteMeta(this.$t('edit_user', {name: data.data.username}))
      }).catch(() => {
        this.error = 'ERROR'
      })
      this.loading = false
    },
  }
}
</script>
