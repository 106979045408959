export default [
    {
        name: 'dashboard',
        url: '/',
        icon: 'mdi mdi-view-dashboard',
        children: null,
        need_permissions: [],
    },
    {
        name: 'clients',
        url: '/clients',
        icon: 'mdi mdi-account-box-multiple',
        need_permissions: ['clients'],
    },
    {
        name: 'agents',
        url: '/agents',
        icon: 'mdi mdi-server',
        need_permissions: ['agents'],
    },
    {
        name: 'management',
        url: null,
        icon: 'mdi mdi-cogs',
        children: [

            {
                name: 'releases',
                url: '/releases',
                icon: 'mdi mdi-cloud-upload',
                need_permissions: ['releases'],
            },
            {
                name: 'users',
                url: '/users',
                icon: 'mdi mdi-account-multiple',
                need_permissions: ['users_list'],
            },
            {
                name: 'subscriptions',
                url: '/subscriptions',
                icon: 'mdi mdi-apps',
                need_permissions: ['subscriptions'],
            },
            {
                name: 'components',
                url: '/components',
                icon: 'mdi mdi-puzzle',
                need_permissions: ['components'],
            },
        ],
    },

]
