<template>
  <div>
    <router-link :to="{name: 'clients'}" class="card ">
      <div class="box bg-white-gradient text-center bg-dark">
        <Preloader v-if="stat === null"></Preloader>
        <h2 class="text-white" v-else >{{stat.clients}}</h2>
        <span class="text-white">{{$t('count_clients')}}</span></div>
    </router-link>
    <router-link :to="{name: 'agents'}" class="card ">
      <div class="box bg-white-gradient text-center bg-dark">
        <Preloader v-if="stat === null"></Preloader>
        <h2 class="text-white" v-else ><span style="color: #8cd48c">{{stat.agents_active}}</span> <span style="color: #c0c0c0">/</span> <span style="color: #d0d0d0">{{stat.agents}}</span></h2>
        <span class="text-white" v-html="$t('count_agents')"></span></div>
    </router-link>
  </div>
</template>


<script>

import Preloader from "../../../components/Helpers/Preloader";
const url = '/admin/release'

export default {
  components: {Preloader},
  props: {
    stat: {
      type: Object,
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  methods: {

  }

}
</script>