<template>
  <div class="page-wrapper">
    <PageBreadCrumb />
      <router-view  />
    <Footer />
  </div>
</template>


<script>
  import PageBreadCrumb from "./PageWrapper/PageBreadCrumb";
  import Footer from "./PageWrapper/Footer";
  export default {
    components: {Footer,  PageBreadCrumb}
  }
</script>