<template>
  <div style="width: 100%; height: 100%;  text-align: center">
    <h1>{{message}}</h1>
    <button class="btn btn-lg btn-outline-dark" @click="reload()"><i class="mdi mdi-refresh" > {{$t('try_reload_page')}}</i></button>
  </div>
</template>


<script>
  export default {
    props: {
      errorMessage: {
        type: String,
        default: '',
      }
    },
    data() {
      return {
        message: this.$t('something_wrong'),
      }
    },
    methods: {
      reload() {
        location.reload();
      }
    },
    mounted() {
      if(this.errorMessage !== '') {
        this.message = this.errorMessage
      }
    }
  }
</script>