<template>
  <div class="container-fluid">
    <div class="row" v-if="error !== ''">
      <div class="col-12">
        <SomethingWrong/>
      </div>
    </div>
    <ModalForm :disabled-buttons="record.disableButtons" :title="record.title" ref="editRecord" @save="saveRecord">
      <template v-slot:content>
        <form class="container" style="padding: 20px">
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('name') }}</label>
            <div class="col-md-9 col-sm-9">
              <input type="text" v-model="record.name"
                     class="form-control" :placeholder="$t('name')">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('company_name') }}</label>
            <div class="col-md-9 col-sm-9">
              <input type="text" v-model="record.company_name"
                     class="form-control" :placeholder="$t('company_name')">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('email') }}</label>
            <div class="col-md-9 col-sm-9">
              <input type="email" v-model="record.email"
                     class="form-control" :placeholder="$t('email')">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('phone') }}</label>
            <div class="col-md-9 col-sm-9">
              <input type="text" v-model="record.phone"
                     class="form-control" :placeholder="$t('phone')">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('password') }}</label>
            <div class="col-md-9 col-sm-9">
              <input type="password" v-model="record.password"
                     class="form-control" :placeholder="$t('password')">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('bill_enabled') }}</label>
            <div class="col-md-9 col-sm-9">
              <input type="checkbox" v-model="record.bill_enabled" style="width: 26px; height: 26px;"
                     class="form-control" :placeholder="$t('bill_enabled')">
            </div>
          </div>
        </form>
      </template>
    </ModalForm>
    <div class="row" v-if="error === ''">
      <div class="col-12">
        <div v-cloak>
          <button class="btn btn-default" @click="addRecord" style="float: right; margin-bottom: 10px"><i
              class="fa fa-plus"></i> {{ $t('create_new_client') }}
          </button>
          <v-server-table ref="tbl" :columns="table.columns" :options="table.options" class="btn-add-margin-top-for-mobile">
            <template v-slot:created_at="{row}">{{ $helpers.formatTime(row.created_at) }}</template>
            <template v-slot:status="{row}">{{ row.status }}</template>
            <template v-slot:name="{row}">
              <div>
                {{row.name}}
                <span v-if="row.company_name !== ''"><br><small>({{row.company_name}})</small></span>
              </div>
            </template>
            <template v-slot:agents="{ row }" >
              <div v-for="agent in row.agents" :key="agent.id" style="min-width: 130px">
                <router-link :to="{name: 'agent_edit', params: {id: agent.id}}" :title="`UUID: ${agent.uuid}\nIP: ${agent.ip_address}`">
                  <span v-if="agent.ip_address !== null">{{agent.ip_address}}</span>
                  <span v-else>{{agent.uuid | truncate(15, "...")}}</span>
                </router-link>
              </div>
            </template>
            <template v-slot:actions="{ row }" >
              <div v-if="$auth.isPermitted('clients.management')">
                <router-link :to="{name: 'client_edit', params: {id:row.id}}" class="btn btn-default" style="margin: 3px" :title="$t('edit')"><i class="fa fa-edit"></i></router-link>
                <button @click="deleteRecord(row.id)" class="btn btn-danger" style="margin: 3px" :title="$t('delete')"><i class="fa fa-trash"></i></button>
              </div>
            </template>
          </v-server-table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import SomethingWrong from "../../components/Helpers/SomethingWrong";
import ModalForm from "../../components/Helpers/ModalForm";

const url = '/admin/client'

export default {
  components: {ModalForm, SomethingWrong,},
  data() {
    return {
      data: [],
      record: {
        id: 0,
        name: "",
        company_name: "",
        email: "",
        phone: "",
        password: "",
        bill_enabled: true,
        disableButtons: false,
      },
      table:  {
        columns: ['id','created_at', 'status', 'name',   'email',   'balance', 'agents', 'actions'],
        options: {
          perPage: 50,
          perPageValues: [50, 100,  500, 1000],
          requestFunction(data) {
            return this.$api.get("/admin/client",  {
              query: data.query,
              asc: data.ascending === 1 ? "asc" : "desc",
              limit: data.limit,
              page: data.page,
              order_by: data.orderBy,
            }).catch(function (e) {
              console.log(e)
            }).then(d => {
              return {data: d.data, count: d.meta.count_records}
            });
          },
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table',
          headings: {
            id: 'Id',
            created_at: this.$t('created_at'),
            name: this.$t('name'),
            uuid: this.$t('uuid'),
            status: this.$t('status'),
            email: this.$t('email'),
            balance: this.$t('balance'),
            agents: this.$t('agents'),
            actions: '',
          },
          sortable: ['id','created_at', 'updated_at', 'name',  'email'],
          filterable: ['email'],

          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
      error: '',
    }
  },
  mounted() {
    this.$setRouteMeta(this.$t('clients'))
    this.load()
  },
  methods: {
    deleteRecord(id) {
      if (confirm(this.$t("are_you_sure_for_delete_record"))) {
        this.loading = true
        this.$api.delete(url + '/' + id).then(() => {
          this.$noty.success(this.$t('record_success_deleted'))
          this.load()
        })
      }
    },
    saveRecord() {
      this.record.disableButtons = true
        this.$api.post(url, {
          name: this.record.name,
          company_name: this.record.company_name,
          email: this.record.email,
          phone: this.record.phone,
          password: this.record.password,
          bill_enabled: true,
        }).then((d) => {
          this.$noty.success(this.$t('record_success_created'))
          this.$refs.editRecord.hide()
          this.record.disableButtons = false
          this.$router.push({name: 'client_edit', params: {id: d.data.id}})
        }).finally(() => {
          this.record.disableButtons = false
        })
    },
    addRecord() {
      this.record = {
        title: this.$t('create_new_record'),
        disableButtons: false,
        name: this.record.name,
        company_name: this.record.company_name,
        email: this.record.email,
        phone: this.record.phone,
        password: this.record.password,
        bill_enabled: true,
      }
      this.$refs.editRecord.show()
    },

    async load() {
      this.$refs.tbl.refresh()
    },
  }
}
</script>
