<template>
  <div class="card" :style="zeroPadding ? 'padding: 0' : ''">
    <div class="card-header waves-effect waves-dark" @click="displayBlock = !displayBlock" style="height: 36px; padding-top: 8px">{{name}}
      <ArrowSlide style="float: right" :def="displayBlock"/>
    </div>

    <transition name="slide">
    <div class="card-body" v-show="displayBlock" :style="zeroPadding ? 'padding: 0' : ''">
      <slot></slot>
    </div>
    </transition>
  </div>
</template>

<script>
import ArrowSlide from "./ArrowSlide";
export default {
  components: {ArrowSlide},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    zeroPadding: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.displayBlock = this.show
  },
  data() {
    return {
        displayBlock: false
    }
  }

}
</script>

<style>
</style>