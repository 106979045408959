<template>
  <div class="container-fluid">
    <div class="row" v-if="loading && error === ''">
      <div class="col-12">
        <Preloader/>
      </div>
    </div>
    <div class="row" v-if="error !== ''">
      <div class="col-12">
        <SomethingWrong/>
      </div>
    </div>
    <ModalForm :disabled-buttons="record.disableButtons" :title="record.title" ref="editRecord" @save="saveRecord">
      <template v-slot:content>
        <form class="container" style="padding: 20px">
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('version') }}</label>
            <div class="col-md-9 col-sm-9">
              <input type="text" v-model="record.version"
                     class="form-control" :placeholder="$t('version')">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-sm-3 text-right control-label col-form-label">{{ $t('change_log') }}</label>
            <div class="col-md-9 col-sm-9">
              <textarea type="text" v-model="record.change_log"
                        class="form-control" :placeholder="$t('change_log')"></textarea>
            </div>
          </div>
        </form>
      </template>
    </ModalForm>
    <div class="row" v-if="!loading && error === ''">
      <div class="col-12">
        <div v-cloak>
          <button class="btn btn-default" @click="addRecord" style="float: right; margin-bottom: 10px"><i
              class="fa fa-plus"></i> {{ $t('create_new_release') }}
          </button>
          <v-client-table ref="table" v-model="data" :columns="table.columns" :options="table.options"
                          style="margin-top: 5px; ">
            <template v-slot:created_at="{row}">{{ $helpers.formatTime(row.created_at) }}</template>
            <template v-slot:updated_at="{row}">{{ $helpers.formatTime(row.updated_at) }}</template>
            <template v-slot:version="{row}"><b>{{row.version}}</b></template>
            <template v-slot:change_log="{row}"><pre style="max-height: 150px; margin: -10px; padding: 0;" >{{row.change_log}}</pre></template>
            <template v-slot:actions="{row}">
              <div>
                <button @click="editRecord(row)" class="btn btn-default" style="margin: 3px" :title="$t('edit')"><i
                    class="fa fa-edit"></i></button>
                <button @click="deleteRecord(row.id)" class="btn btn-danger" style="margin: 3px" :title="$t('delete')">
                  <i class="fa fa-trash"></i></button>
              </div>
            </template>
          </v-client-table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
"use strict";
import Preloader from "../../components/Helpers/Preloader";
import SomethingWrong from "../../components/Helpers/SomethingWrong";
import ModalForm from "../../components/Helpers/ModalForm";

const url = '/admin/release'

export default {
  components: {ModalForm, SomethingWrong, Preloader},
  data() {
    return {
      data: [],
      record: {
        id: 0,
        created_at: '',
        updated_at: '',
        version: '',
        change_log: '',
      },
      table: {
        columns: ['id', 'created_at', 'updated_at', 'version', 'change_log', 'actions'],
        options: {
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table',
          headings: {
            id: 'Id',
            created_at: this.$t('created_at'),
            updated_at: this.$t('updated_at'),
            version: this.$t('version'),
            change_log: this.$t('change_log'),
            actions: '',
          },
          sortable: ['id', 'version'],
          filterable: ['version', 'change_log'],
          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
      loading: true,
      error: '',
    }
  },
  async mounted() {
    this.$setRouteMeta(this.$t('releases'))
    this.loading = true
    this.load()
  },
  methods: {
    deleteRecord(id) {
      if (confirm(this.$t("are_you_sure_for_delete_record"))) {
        this.loading = true
        this.$api.delete(url + '/' + id).then(() => {
          this.$noty.success(this.$t('record_success_deleted'))
          this.load()
        })
      }
    },
    saveRecord() {
      this.record.disableButtons = true
      if (this.record.id !== 0) {
        this.$api.put(url + '/' + this.record.id, {
          version: this.record.version,
          change_log: this.record.change_log,
        }).then(() => {
          this.load()
          this.$noty.success(this.$t('record_success_updated'))
          this.$refs.editRecord.hide()
        }).finally(() => {
          this.record.disableButtons = false
        })
      } else {
        this.$api.post(url, {
          version: this.record.version,
          change_log: this.record.change_log,
        }).then(() => {
          this.load()
          this.$noty.success(this.$t('record_success_created'))
          this.$refs.editRecord.hide()
        }).finally(() => {
          this.record.disableButtons = false
        })
      }
    },
    addRecord() {
      this.record = {
        title: this.$t('create_new_record'),
        disableButtons: false,
        created_at: '',
        updated_at: '',
        id: 0,
        version: '',
        change_log: '',
      }
      this.$refs.editRecord.show()
    },
    editRecord(record) {
      this.record = {
        title: this.$t('edit_record_with_key', {id: record.key}),
        disableButtons: false,
        id: record.id,
        created_at: record.created_at,
        updated_at: record.updated_at,
        version: record.version,
        change_log:  record.change_log,
      }
      this.$refs.editRecord.show()
    },
    tableData(dt) {
      var data = []
      dt.forEach(record => {
        data.push({
          disableButtons: false,
          title: this.$t('edit_record_with_key', {id: record.key}),
          created_at: record.created_at,
          updated_at: record.updated_at,
          id: record.id,
          version: record.version,
          change_log:  record.change_log,
        })
      })
      return data
    },
    async load() {
      this.loading = true
      await this.$api.get(url).then(data => {
        this.data = this.tableData(data.data)
      }).catch(() => {
        this.error = 'ERROR'
      })
      this.loading = false
    },
  }
}
</script>

<style>
#app {
  width: 95%;
  margin: 0 auto;
}

.VuePagination {
  text-align: center;
}

.vue-title {
  text-align: center;
  margin-bottom: 10px;
}

.vue-pagination-ad {
  text-align: center;
}

.glyphicon.glyphicon-eye-open {
  width: 16px;
  display: block;
  margin: 0 auto;
}

th:nth-child(3) {
  text-align: center;
}

.VueTables__child-row-toggler {
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}

.VueTables__child-row-toggler--closed::before {
  content: "+";
}

.VueTables__child-row-toggler--open::before {
  content: "-";
}

[v-cloak] {
  display: none;
}

</style>