export default {
    license: "License",
    create_license: "Create license",
    enter_vendor: "Enter vendor ID",
    payments: "Payments",
    payment_type: "Payment type",
    amount: "Amount $",
    actions: "Actions",
    add_payment: "Add payment",
    order_description: "Order description",
    bill_enabled: "Bill enabled",
    pay_period: "Pay period",
    charging_amount_by_interface: "Charging amount by interface",
    type: "Type",
    subscription_type_name: "Subscription type",
    reserved_interfaces: "Reserved interfaces",
    status: "Status",
    subscription_type: {
        AGENT: "Agent",
        COMPONENTS: "Components",
    },
    client_statuses: {
      ACTIVE: 'Active',
      NOT_CONFIRMED: 'Not confirmed',
      DEACTIVATED: 'Deactivated',
    },
    email_queue: "Email queue",
    country: "Country",
    location: "Location",
    city: "City",
    count_interfaces: "Count interfaces",
    dependencies: "Dependencies",
    count_clients: "Count clients",
    count_agents: "Count agents<br><small>Active/All</small>",
    no_data_usage_statistic: "No data for display usage statistic",
    agent_usage_stat: "Agent usage statistic",
    general_stat: "General stat",
    count_groups: "Count groups",
    count_devices: "Count devices",
    count_users: "Count users",
    count_devices_by_models: "Devices by models",
    device_model_key: "Device model key",
    lang_short: 'en',
    include_agent: "Include agent",
    add_agent: "Add agent",
    agent_success_added: "Agent success added",
    expired_at: "Expired At",
    subscription_info: "Subscription info",
    choose_subscription_for_display_info: "Choose subscription for display info",
    subscription_detail: "Subscription detail",
    no_active_subscription: "No active subscription",
    add_subscription: "Add subscription",
    charged_amount: "Charged amount",
    subscription: "Subscription",
    renew_automatically: "Renew automatically",
    agent_successful_updated: "Agent successful updated",
    no_components: "No components",
    subscription_success_added: "Subscription success added",
    agent_is_enabled: "Agent is enabled",
    active_until: "Active until",
    sign_in: "Sign in",
    username: "Username",
    password: "Password",
    unknown_error: "Unknown error",
    login_or_password_incorrect: "Incorrect login or password",
    account_settings: "Account settings",
    logout: "Logout",
    renew_success_updated_for_subscription: "Renew success updated for subscription",
    type_more_symbols_for_search: "Type more symbols for search...",
    created_at: "Created At",
    updated_at: "Updated At",
    id: "ID",
    clients: "Clients",
    name: "Name",
    company_name: "Company name",
    phone: "Phone",
    balance: "Balance",
    agents: "Agents",
    create_new_client: "Create new client",
    edit_client: "Client {name}",
    client_successful_updated: "Client success updated",
    agents_not_found: "Agents not found",
    not_registered: "Not registered",
    not_active: "Not active",
    ip_address: "IP address",
    agent_edit: "Edit agent",
    last_active: "Last active",
    client: "Client",
    loading: "Loading...",
    create_new_agent: "Create new agent",
    ip: "IP",
    uuid: "UUID",
    UUID: "UUID",
    general_info: "General info",
    login: "Login",
    email: "Email",
    role: "Role",
    roles: {
        ADMIN: "Admin",
        USER: "User",
    },
    charging_period: "Charge period",
    charging_periods: {
        MONTH: "Monthly",
        YEAR: "Yearly",
    },
    charging_amount: "Charging amount",
    subscription_management: "Subscription management",
    create_new_subscription: "Create new subscription",
    user_list: "List users",
    user: "User",
    users: "Users",
    search_placeholder: "Search...",
    create_new_user_btn: "Create new user",
    edit: "Edit",
    delete: "Delete",
    something_wrong: "Somethings wrong",
    try_reload_page: "Reload page",
    are_you_sure_for_delete_user: "Are you sure for delete user?",
    my_account: "My account",
    dashboard: "Dashboard",
    create_new_user: "Create new user",
    user_successful_updated: "User successful updated",
    edit_user: "Edit user {name}",
    personal_info: "Personal info",
    new_password: "New password",
    save: "Save",
    create: "Create",
    components_management: "Component management",
    create_new_component: "Create new component",
    key: "Key",
    archive_name: "Archive name",
    description: "Description",
    create_new_record: "Create new record",
    close: "Close",
    edit_record_with_key: "Edit record {key}",
    record_success_created: "Record success created",
    record_success_updated: "Record success updated",
    record_success_deleted: "Record success deleted",
    components: "Components",
    subscriptions: "Subscriptions",
    are_you_sure_for_delete_record: "Are you sure for delete record?",
    management: 'Management',
    releases: "Releases",
    create_new_release: "Create new release",
    version: "Version",
    change_log: "Change log",
    //DataTable block
    dt_table: {
        count: "Displayed {from} to {to} from {count} records|{count} records|One record",
        first: 'First',
        last: 'Last',
        filter: "Filter:",
        filterPlaceholder: "Search",
        limit: "Limit:",
        page: "Page:",
        noResults: "Records not found",
        filterBy: "Filter by {column}",
        loading: 'Loading...',
        defaultOption: 'Choose {column}',
        columns: 'Columns'
    },
}
