<template>
  <div class="container-fluid">
    <div class="row" v-if="stat === null">
      <div class="col-12">
        <Preloader ></Preloader>
      </div>
    </div>
    <div class="row" v-if="stat !== null">
      <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
        <StatBlock :stat="stat"></StatBlock>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
        <SummaryByVersions :list="stat.versions"></SummaryByVersions>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
        <SummaryDevicesByModel  :list="stat.usage_stat.by_device"></SummaryDevicesByModel>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
        <TopAgentsByDevices :list="stat.usage_stat.top_agents_by_devices"></TopAgentsByDevices>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
        <LastReleases></LastReleases>
      </div>
    </div>
  </div>
</template>


<script>
import LastReleases from "@/pages/Dashboard/Blocks/LastReleases";
import StatBlock from "@/pages/Dashboard/Blocks/StatBlock";
import TopAgentsByDevices from "./Blocks/TopAgentsByDevices";
import Preloader from "../../components/Helpers/Preloader";
import SummaryDevicesByModel from "./Blocks/SummaryDevicesByModel";
import SummaryByVersions from "@/pages/Dashboard/Blocks/SummaryByVersions.vue";
export default {
  data() {
    return {stat: null,}
  },
  components: {SummaryByVersions, SummaryDevicesByModel, Preloader, TopAgentsByDevices, LastReleases, StatBlock},

  methods: {
    async load() {
      this.loading = true
      await this.$api.get('/admin/dashboard/counts').then(data => {
        this.stat = data.data
      }).catch(() => {
        this.error = 'ERROR'
      })
      this.loading = false
    },
  },

  mounted() {
    this.load()
  },
}
</script>